import Vue from "vue";
import Router from "vue-router";
import VueMeta from "vue-meta";
import store from "../store/index";

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => {
    if (Router.isNavigationFailure(err, Router.NavigationFailureType.redirected)) {
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}

const originalReplace = Router.prototype.replace
Router.prototype.replace = function replace(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch((err) => {
    if (Router.isNavigationFailure(err, Router.NavigationFailureType.redirected)) {
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}

Vue.use(Router);
Vue.use(VueMeta);
/*
 * useful meta for routes:
 * public - routes accessible without auth
 * protected - pages accessible only to authenticated users
 * private - pages accessible only to defined list of users\groups
 * */

let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Index"
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("@/views/Login/Signin"),
      meta: { public: true }
    },
    {
      path: "/password_reset/:token",
      name: "PasswordReset",
      component: () => import("@/views/Login/PasswordReset"),
      meta: { public: true },
      props: true
    },
    {
      path: "/password_reset",
      name: "PasswordResetRequest",
      component: () => import("@/views/Login/PasswordResetRequest"),
      meta: { public: true },
      props: true
    },
    {
      path: "/view_in_browser/:accountId/:campaignTrackingToken/:emailToken",
      name: "ViewInBrowser",
      component: () => import("@/views/ViewInBrowser/ViewInBrowser.vue"),
      meta: { public: true },
      props: true
    },
    {
      path: "/accounts",
      name: "Accounts",
      meta: { protected: true, account_needed: true },
      component: () => import("@/views/Accounts/AccountsView"),
    },
    {
      path: "/contacts/search/:searchId?",
      name: "ContactsSearch",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params }),
      component: () => import("@/views/Contacts/SearchView")
    },
    {
      path: "/contacts/:id/similar",
      name: "FindSimilar",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/Contacts/FindSimilar"),
    },
    {
      path: "/contacts/add",
      name: "AddContact",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/Contacts/AddContact"),
    },
    {
      path: "/contacts/add_single",
      name: "AddSingleContact",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/Contacts/AddSingleContact"),
    },
    {
      path: "/contacts/bulk_import",
      name: "BulkImportContact",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/Contacts/BulkImportContact"),
    },
    {
      path: "/contacts/match_tool",
      name: "ContactMatchTool",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/Contacts/ContactMatchTool"),
    },
    {
      path: "/contacts/:id",
      name: "ContactDetails",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/Contacts/ContactProfile.vue"),
    },
    {
      path: "/contacts/:id/edit",
      name: "EditContact",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/Contacts/EditContact"),
    },
    {
      path: "/global_deployments",
      name: "GlobalDeployments",
      meta: { protected: true },
      props: true,
      component: () => import("@/views/GlobalDeployments/CampaignsCalendarView"),
    },
    {
      path: "/npi_match",
      name: "NpiMatch",
      meta: { protected: true },
      props: true,
      component: () => import("@/views/NpiMatch/NpiMatchView"),
    },
    {
      path: "/logout",
      name: "Logout",
      meta: { protected: true },
      props: true,
      component: () => import("@/views/Logout"),
    },
    {
      path: "/segments/search",
      name: "SegmentsSearch",
      meta: { protected: true },
      props: true,
      component: () => import("@/views/Segments/SegmentsView"),
    },
    {
      path: "/campaigns/search",
      name: "CampaignsSearch",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/Campaigns/CampaignsView"),
    },
    {
      path: "/media_library",
      name: "MediaLibrary",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/MediaLibrary/MediaLibraryView"),
    },
    {
      path: "/campaigns/simple/:id",
      name: "SimpleCampaign",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/Campaigns/SimpleCampaignView"),
    },
    {
      path: "/campaigns/schedule/:id",
      name: "ScheduleCampaign",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/Campaigns/ScheduleCampaignView"),
    },
    {
      path: "/campaigns/fromlines",
      name: "FromLines",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/Campaigns/FromLinesView"),
    },
    {
      path: "/campaigns/automated/:id",
      name: "AutomatedCampaign",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/Campaigns/AutomationCampaign"),
    },
    {
      path: "/leadScoreRules",
      name: "LeadScoreRules",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/LeadScore/LeadScoreRulesView"),
    },
    {
      path: "/unsubscribe/:accountId/:campaignTrackingToken/:emailToken",
      name: "Unsubscribe",
      meta: { public: true },
      props: true,
      component: () => import("@/views/Unsubscribe/UnsubscribeView"),
    },
    {
      path: "/change_details/:accountId/:campaignTrackingToken/:emailToken",
      name: "ChangeUnsubscribeDetails",
      meta: { public: true },
      props: true,
      component: () => import("@/views/Unsubscribe/UnsubscribeView"),
    },
    {
      path: "/verify-from-line/:accountId/:token",
      name: "VerifyFromLine",
      meta: { public: true },
      props: true,
      component: () => import("@/views/Campaigns/VerifyFromLineView"),
    },
    {
      path: "/plugins",
      name: "Plugins",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/Plugins/Plugins"),
    },
    {
      path: "/plugins/install/smarty/:id?",
      name: "AddPluginSmarty",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
      component: () => import("@/views/Plugins/AddPluginSmarty"),
    },
    {
      path: "/plugins/install/squint_metrics/:id?",
      name: "AddPluginSquintMetrics",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
      component: () => import("@/views/Plugins/AddPluginSquintMetrics"),
    },
    {
      path: "/plugins/install/click_send/:id?",
      name: "AddPluginClickSend",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
      component: () => import("@/views/Plugins/AddPluginClicksend"),
    },
    {
      path: "/plugins/install/automated_export/:id?",
      name: "AddPluginAutomatedExport",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
      component: () => import("@/views/Plugins/AddPluginAutomatedExport"),
    },
    {
      path: "/plugins/install/automated_import/:id?",
      name: "AddPluginAutomatedImport",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
      component: () => import("@/views/Plugins/AddPluginAutomatedImport"),
    },
    {
      path: "/plugins/install/google_analytics/:id?",
      name: "AddPluginGoogleAnalytics",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
      component: () => import("@/views/Plugins/AddPluginGoogleAnalytics"),
    },
    {
      path: "/plugins/install/demo/:id?",
      name: "AddPluginDemo",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
      component: () => import("@/views/Plugins/AddPluginDemo"),
    },
    {
      path: "/google/analytics",
      name: "GoogleAnalytics",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/Plugins/Google/GoogleAnalyticsView"),
    },
    // {
    //   path: "/plugins/install/google_search/:id?",
    //   name: "AddPluginGoogleSearch",
    //   meta: { protected: true, account_needed: true },
    //   props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
    //   component: () => import("@/views/Plugins/AddPluginGoogleSearch"),
    // },
    // {
    //   path: "/google/search",
    //   name: "GoogleSearch",
    //   meta: { protected: true, account_needed: true },
    //   props: true,
    //   component: () => import("@/views/Google/GoogleSearchView"),
    // },
    {
      path: "/plugins/install/ubc_alcon/:id?",
      name: "AddPluginUBCAlcon",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
      component: () => import("@/views/Plugins/AddPluginUBCAlcon"),
    },
    {
      path: "/ubc_alcon/import",
      name: "PluginUbcAlconImport",
      meta: { protected: true, account_needed: true },
      component: () => import("@/views/Plugins/UbcAlcon/UbcAlconImportsView"),
    },
    {
      path: "/ubc_alcon/export",
      name: "PluginUbcAlconExport",
      meta: { protected: true, account_needed: true },
      component: () => import("@/views/Plugins/UbcAlcon/UbcAlconExportsView"),
    },
    {
      path: "/plugins/install/arrowhead/:id?",
      name: "AddPluginArrowhead",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
      component: () => import("@/views/Plugins/AddPluginArrowhead"),
    },
    {
      path: "/arrowhead/import",
      name: "PluginArrowheadImport",
      meta: { protected: true, account_needed: true },
      props: {component_name: 'AddPluginArrowhead'},
      component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
    },
    {
      path: "/plugins/install/biopharm/:id?",
      name: "AddPluginBioPharm",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
      component: () => import("@/views/Plugins/AddSharedPlugin"),
    },
    {
      path: "/biopharm/import",
      name: "BioPharmImport",
      meta: { protected: true, account_needed: true },
      props: {component_name: 'AddPluginBioPharm'},
      component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
    },
    {
      path: "/plugins/install/optimizerx/:id?",
      name: "AddPluginOptimizeRx",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
      component: () => import("@/views/Plugins/AddSharedPlugin"),
    },
    {
      path: "/optimizerx/import",
      name: "PluginOptimizeRxImport",
      meta: { protected: true, account_needed: true },
      props: {component_name: 'AddPluginOptimizeRx'},
      component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
    },
    {
      path: "/plugins/install/pulsepointlife/:id?",
      name: "AddPluginPulsePointLife",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
      component: () => import("@/views/Plugins/AddSharedPlugin"),
    },
    {
      path: "/pulsepointlife/import",
      name: "PluginPulsePointLifeImport",
      meta: { protected: true, account_needed: true },
      props: {component_name: 'AddPluginPulsePointLife'},
      component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
    },
    {
      path: "/plugins/install/bulletin_healthcare/:id?",
      name: "AddPluginBulletinHealthcare",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
      component: () => import("@/views/Plugins/AddSharedPlugin"),
    },
    {
      path: "/bulletin_healthcare/import",
      name: "BulletinHealthcareImport",
      meta: { protected: true, account_needed: true },
      props: {component_name: 'AddPluginBulletinHealthcare'},
      component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
    },
    {
      path: "/plugins/install/rxnt/:id?",
      name: "AddPluginRxNT",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
      component: () => import("@/views/Plugins/AddSharedPlugin"),
    },
    {
      path: "/rxnt/import",
      name: "RxNTImport",
      meta: { protected: true, account_needed: true },
      props: {component_name: 'AddPluginRxNT'},
      component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
    },
    {
      path: "/plugins/install/mckesson/:id?",
      name: "AddPluginMcKesson",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
      component: () => import("@/views/Plugins/AddPluginMcKesson"),
    },
    {
      path: "/mckesson/import",
      name: "PluginMcKessonImport",
      meta: { protected: true, account_needed: true },
      props: {component_name: 'AddPluginMcKesson'},
      component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
    },
    {
      path: "/plugins/install/doximity/:id?",
      name: "AddPluginDoximity",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
      component: () => import("@/views/Plugins/AddSharedPlugin"),
    },
    {
      path: "/doximity/import",
      name: "PluginDoximityImport",
      meta: { protected: true, account_needed: true },
      props: {component_name: 'AddPluginDoximity'},
      component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
    },
    {
      path: "/plugins/install/prescription_data/:id?",
      name: "AddPluginPrescriptionData",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
      component: () => import("@/views/Plugins/AddPluginPrescriptionData"),
    },
    {
      path: "/prescription_data/import",
      name: "PluginPrescriptionDataImport",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, component_name: 'AddPluginPrescriptionData' }),
      component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
    },
    {
      path: "/prescription_data/report",
      name: "PrescriptionReportView",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
      component: () => import("@/views/Plugins/PrescriptionData/PrescriptionReportView"),
    },
    {
      path: "/plugins/install/webmd/:id?",
      name: "AddPluginWebMD",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
      component: () => import("@/views/Plugins/WebMDSharedPlugin"),
    },
    {
      path: "/webmd/import",
      name: "WebMDImport",
      meta: { protected: true, account_needed: true },
      props: {component_name: 'AddPluginWebMD'},
      component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
    },
    {
      path: "/plugins/install/mdedge/:id?",
      name: "AddPluginMDedge",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
      component: () => import("@/views/Plugins/WebMDSharedPlugin"),
    },
    {
      path: "/mdedge/import",
      name: "MDedgeImport",
      meta: { protected: true, account_needed: true },
      props: {component_name: 'AddPluginMDedge'},
      component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
    },
    {
      path: "/plugins/install/medscape/:id?",
      name: "AddPluginMedscape",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
      component: () => import("@/views/Plugins/WebMDSharedPlugin"),
    },
    {
      path: "/medscape/import",
      name: "MedscapeImport",
      meta: { protected: true, account_needed: true },
      props: {component_name: 'AddPluginMedscape'},
      component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
    },
    {
      path: "/plugins/install/veradigm/:id?",
      name: "AddPluginVeradigm",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
      component: () => import("@/views/Plugins/AddSharedPlugin"),
    },
    {
      path: "/veradigm/import",
      name: "VeradigmImport",
      meta: { protected: true, account_needed: true },
      props: {component_name: 'AddPluginVeradigm'},
      component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
    },
    {
      path: "/plugins/install/notification/:id?",
      name: "AddPluginNotification",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
      component: () => import("@/views/Plugins/AddPluginNotification"),
    },
    {
      path: "/notification",
      name: "PluginNotificationView",
      meta: { protected: true, account_needed: true },
      component: () => import("@/views/Plugins/Notification/PluginNotificationView"),
    },
    {
      path: "/plugins/install/lahlouh/:id?",
      name: "AddPluginLahlouh",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
      component: () => import("@/views/Plugins/AddPluginLahlouh"),
    },
    {
      path: "/lahlouh/import",
      name: "PluginLahlouhImport",
      meta: { protected: true, account_needed: true },
      component: () => import("@/views/Plugins/Lahlouh/LahlouhImportsView"),
    },
    {
      path: "/lahlouh/export",
      name: "PluginLahlouhExport",
      meta: { protected: true, account_needed: true },
      component: () => import("@/views/Plugins/Lahlouh/LahlouhExportsView"),
    },
    {
      path: "/plugins/install/dcc/:id?",
      name: "AddPluginDCC",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
      component: () => import("@/views/Plugins/AddPluginDCC"),
    },
    {
      path: "/dcc/import",
      name: "PluginDccImport",
      meta: { protected: true, account_needed: true },
      component: () => import("@/views/Plugins/Dcc/DccImportsView"),
    },
    {
      path: "/dcc/export",
      name: "PluginDccExport",
      meta: { protected: true, account_needed: true },
      component: () => import("@/views/Plugins/Dcc/DccExportsView"),
    },
    {
      path: "/plugins/install/pvalue/:id?",
      name: "AddPluginPvalue",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
      component: () => import("@/views/Plugins/AddPluginPvalue"),
    },
    {
      path: "/pvalue/import",
      name: "PluginPvalueImport",
      meta: { protected: true, account_needed: true },
      props: {component_name: 'AddPluginPvalue'},
      component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
    },
    {
      path: "/plugins/install/sermo/:id?",
      name: "AddPluginSermo",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
      component: () => import("@/views/Plugins/AddSharedPlugin"),
    },
    {
      path: "/sermo/import",
      name: "SermoImport",
      meta: { protected: true, account_needed: true },
      props: {component_name: 'AddPluginSermo'},
      component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
    },
    {
      path: "/plugins/install/veeva/:id?",
      name: "AddPluginVeeva",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
      component: () => import("@/views/Plugins/AddPluginVeeva"),
    },
    {
      path: "/veeva/config/:accountId",
      name: "PluginVeevaConfig",
      meta: { protected: true, account_needed: false },
      props: route => ({ ...route.query, ...route.params, installed: true }),
      component: () => import("@/views/Plugins/AddPluginVeeva"),
    },
    {
      path: "/plugins/install/vumedi/:id?",
      name: "AddPluginVuMedi",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
      component: () => import("@/views/Plugins/AddSharedPlugin"),
    },
    {
      path: "/vumedi/import",
      name: "PluginVuMediImport",
      meta: { protected: true, account_needed: true },
      props: {component_name: 'AddPluginVuMedi'},
      component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
    },
    {
      path: "/plugins/install/ehs/:id?",
      name: "AddPluginEHealthcareSolutions",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
      component: () => import("@/views/Plugins/AddSharedPlugin"),
    },
    {
      path: "/ehs/import",
      name: "EHealthcareSolutionsImport",
      meta: { protected: true, account_needed: true },
      props: {component_name: 'AddPluginEHealthcareSolutions'},
      component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
    },
    {
      path: "/api_tokens",
      name: "ApiTokens",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/ApiTokens/ApiTokensView"),
    },
    {
      path: "/edit_user/:id",
      name: "ManageUser",
      meta: { protected: true },
      props: true,
      component: () => import("@/views/Users/ManagerUserView"),
    },
    {
      path: "/new_user",
      name: "NewUser",
      meta: { protected: true },
      props: true,
      component: () => import("@/views/Users/ManagerUserView"),
    },
    {
      path: "/my_profile",
      name: "MyProfile",
      meta: { protected: true },
      props: true,
      component: () => import("@/views/Users/MyProfileView"),
    },
    {
      path: "/user",
      name: "ManagerUserList",
      meta: { protected: true },
      props: true,
      component: () => import("@/views/Users/ManagerUserListView"),
    },
    {
      path: "/database",
      name: "ManagerDatabaseList",
      meta: { protected: true },
      props: true,
      component: () => import("@/views/Databases/ManagerDatabaseListView"),
    },
    {
      path: "/campaigns/creatives",
      name: "EmailCreatives",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/Campaigns/EmailCreativesView"),
    },
    {
      path: "/campaigns/creatives/:id",
      name: "EditEmailCreative",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/Campaigns/EditEmailCreativeView"),
    },
    {
      path: "/web_tracking",
      name: "WebTrackingScripts",
      meta: { protected: true },
      props: true,
      component: () => import("@/views/WebTracking/WebTrackingScriptsView"),
    },
    {
      path: "/reports/email",
      name: "EmailReport",
      meta: { protected: true, account_needed: true },
      props: route => {
        let selectedCampaignIDs, selectedEmailSendIDs, selectedSegmentIDs = [];

        const queryCampaignIDs = route.query?.selectedCampaignIDs;
        const queryEmailSendIDs = route.query?.selectedEmailSendIDs;
        const querySegmentIDs = route.query?.selectedSegmentIDs;

        if (queryCampaignIDs) {
          selectedCampaignIDs = Array.isArray(queryCampaignIDs)
              ? queryCampaignIDs?.map(id => parseInt(id))
              : [parseInt(queryCampaignIDs)];
        }
        if (queryEmailSendIDs) {
          selectedEmailSendIDs = Array.isArray(queryEmailSendIDs)
              ? queryEmailSendIDs.slice(0, 5)?.map(id => parseInt(id))
              : [parseInt(queryEmailSendIDs)];
        }
        if (querySegmentIDs && queryEmailSendIDs.length <= 3) {
          selectedSegmentIDs = Array.isArray(querySegmentIDs)
              ? querySegmentIDs.slice(0, 5).map(id => parseInt(id))
              : [parseInt(querySegmentIDs)];
        }

        return {
          selectedCampaignIDs,
          selectedEmailSendIDs,
          selectedSegmentIDs,
        };
      },
      component: () => import("@/views/Reports/Email/EmailReport"),
    },
    {
      path: "/dashboard",
      name: "DashboardList",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/Dashboards/DashboardListView"),
    },
    {
      path: "/new_account",
      name: "NewAccount",
      meta: { protected: true },
      props: true,
      component: () => import("@/views/Accounts/NewAccountView"),
    },
    {
      path: "/edit_account/:id",
      name: "EditAccount",
      meta: { protected: true, private: ['ROLE_PULSE_ADMIN'] },
      props: true,
      component: () => import("@/views/Accounts/EditAccountView"),
    },
    {
      path: "/personalization_settings/:id",
      name: "PersonalizationSettings",
      meta: { protected: true, private: ['ROLE_PULSE_ADMIN'] },
      props: true,
      component: () => import("@/views/Accounts/PersonalizationSettingsView"),
    },
    {
      path: "/source",
      name: "Sources",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/Contacts/SourcesView"),
    },
    {
      path: "/dashboard/:id/edit",
      name: "EditDashboard",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/Dashboards/EditDashboard"),
    },
    {
      path: "/dashboard/:id",
      name: "DashboardView",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/Dashboards/DashboardView"),
    },
    {
      path: "/blacklist",
      name: "Blacklist",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/Campaigns/BlackListView"),
    },
    {
      path: "/channel/alerts",
      name: "Alerts",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/Channels/AlertView"),
    },
    {
      path: "/channel/banners",
      name: "Banners",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/Channels/BannerView"),
    },
    {
      path: "/channel/direct_mail",
      name: "DirectMail",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/Channels/DirectMailView"),
    },
    {
      path: "/channel/events_conferences",
      name: "Events",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/Channels/EventsView"),
    },
    {
      path: "/channel/forms",
      name: "Forms",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/Channels/FormView"),
    },
    {
      path: "/channel/forms/create",
      name: "CreateForm",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/Channels/EditFormView"),
    },
    {
      path: "/channel/forms/:id/edit",
      name: "EditForm",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/Channels/EditFormView"),
    },
    {
      path: "/campaigns/bulk_unsubscribe",
      name: "BulkUnsubscribe",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/Campaigns/BulkUnsubscribeView"),
    },
    {
      path: "/reports/banner",
      name: "BannerReport",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/Reports/Banner/BannerReportView"),
    },
    {
      path: "/reports",
      name: "Reports",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/Reports/ReportsView"),
    },
    {
      path: "/exports",
      name: "ExportsView",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/Exports/ExportsView"),
    },
    {
      path: "/imports",
      name: "ImportsView",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/Imports/ImportsView"),
    },
    {
      path: "/actions/search",
      name: "SearchActions",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/Actions/SearchActions"),
    },
    {
      path: "/actions/import",
      name: "ImportActions",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/Actions/ImportActions"),
    },
    {
      path: "/reports/email_subscription_status",
      name: "EmailSubscriptionStatusReport",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/Reports/EmailSubscriptionStatusReport"),
    },
    {
      path: "/imports/:id/contact_import_reviews",
      name: "ContactImportProcessView",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/Imports/ContactImportProcessView"),
    },
    {
      path: "/reports/engagement",
      name: "EngagementReport",
      meta: { protected: true, account_needed: true },
      props: true,
      component: () => import("@/views/Reports/EngagementReportView"),
    },
    {
      path: "/plugins/install/mdlinx/:id?",
      name: "AddPluginMDLinx",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
      component: () => import("@/views/Plugins/AddSharedPlugin"),
    },
    {
      path: "/mdlinx/import",
      name: "PluginMDLinxImport",
      meta: { protected: true, account_needed: true },
      props: {component_name: 'AddPluginMDLinx'},
      component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
    },
    {
      path: "/plugins/install/mng_health/:id?",
      name: "AddPluginMngHealth",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
      component: () => import("@/views/Plugins/AddSharedPlugin"),
    },
    {
      path: "/mng_health/import",
      name: "PluginMngHealthImport",
      meta: { protected: true, account_needed: true },
      props: {component_name: 'AddPluginMngHealth'},
      component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
    },
    {
      path: "/plugins/install/reachmd/:id?",
      name: "AddPluginReachMD",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
      component: () => import("@/views/Plugins/AddSharedPlugin"),
    },
    {
      path: "/reachmd/import",
      name: "PluginReachMDImport",
      meta: { protected: true, account_needed: true },
      props: {component_name: 'AddPluginReachMD'},
      component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
    },
    {
      path: "/plugins/install/rep_management/:id?",
      name: "AddPluginRepManagement",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
      component: () => import("@/views/Plugins/AddPluginRepManagement"),
    },
    {
      path: "/rep_management/import",
      name: "PluginRepManagementImport",
      meta: { protected: true, account_needed: true },
      props: {component_name: 'AddPluginRepManagement'},
      component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
    },
    {
      path: "/rep_management/export",
      name: "PluginRepManagementExport",
      meta: { protected: true, account_needed: true },
      component: () => import("@/views/Plugins/RepManagement/RepManagementExportsView"),
    },
    {
      path: "/rep_management/report",
      name: "RepManagementReportView",
      meta: { protected: true, account_needed: true },
      component: () => import("@/views/Plugins/RepManagement/RepManagementReportView"),
    },
    {
      path: "/plugins/install/typeform/:id?",
      name: "AddPluginTypeform",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
      component: () => import("@/views/Plugins/AddPluginTypeform"),
    },
    {
      path: "/kn-base",
      name: "KnowledgeBase",
      beforeEnter() {window.open('https://kb.pulsehealth.tech/', '_blank')}
    },
    {
      path: "/plugins/install/epocrates/:id?",
      name: "AddPluginEpocrates",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
      component: () => import("@/views/Plugins/AddPluginEpocrates"),
    },
    {
      path: "/epocrates/import",
      name: "PluginEpocratesImport",
      meta: { protected: true, account_needed: true },
      props: {component_name: 'AddPluginEpocrates'},
      component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
    },
    {
      path: "/phone/validation",
      name: "PhoneConfirm",
      meta: { protected: true },
      props: true,
      component: () => import("@/views/Login/PhoneConfirm"),
    },
    {
      path: "/phone/code/validation",
      name: "PhoneCodeConfirm",
      meta: { protected: true },
      props: true,
      component: () => import("@/views/Login/PhoneCodeConfirm"),
    },
    {
      path: "/plugins/install/healthcasts/:id?",
      name: "AddPluginHealthcasts",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
      component: () => import("@/views/Plugins/AddSharedPlugin"),
    },
    {
      path: "/healthcasts/import",
      name: "HealthcastsImport",
      meta: { protected: true, account_needed: true },
      props: {component_name: 'AddPluginHealthcasts'},
      component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
    },
    {
      path: "/plugins/install/haymarket/:id?",
      name: "AddPluginHaymarket",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
      component: () => import("@/views/Plugins/AddSharedPlugin"),
    },
    {
      path: "/haymarket/import",
      name: "HaymarketImport",
      meta: { protected: true, account_needed: true },
      props: {component_name: 'AddPluginHaymarket'},
      component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
    },
    {
      path: "/plugins/install/doceree/:id?",
      name: "AddPluginDoceree",
      meta: { protected: true, account_needed: true },
      props: route => ({ ...route.query, ...route.params, installed: (String(route.query.installed).toLowerCase() === 'true') }),
      component: () => import("@/views/Plugins/AddSharedPlugin"),
    },
    {
      path: "/doceree/import",
      name: "DocereeImport",
      meta: { protected: true, account_needed: true },
      props: {component_name: 'AddPluginDoceree'},
      component: () => import("@/views/Plugins/SharedImportPlugins/SharedImportPluginsView"),
    },
    {
      path: "*",
      name: "PageNotFound",
      component: () => import("@/views/PageNotFound"),
      meta: { protected: true },
    }
  ],
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});

router.beforeEach(async (to, from, next) => {
  if (store.getters["user/user_info"].id === undefined && localStorage.getItem('user')) {
    const user_details = JSON.parse(localStorage.getItem('user'));
    store.commit('user/update_user_info', {
      ...user_details
    });
  }

  // The user went to a new page, lets update the last activity time in case there's no API calls on the page
  store.commit('user/updateLastActivityDateTime', new Date());
  if (to.hash && to.hash.startsWith("#!")) {
    return next({ path: to.hash.replace("#!", "/"), query: to.query });
  }

  // Handle public routes
  if (
      to.name === 'Login'
      || to.name === 'PasswordResetRequest'
      || to.name === 'PasswordReset'
      || to.name === 'Unsubscribe'
      || to.name === 'ChangeUnsubscribeDetails'
      || to.name === 'VerifyFromLine'
      || to.meta.public
  ) {
    /*eslint no-unused-vars: ["error", { "ignoreRestSiblings": true }]*/
    const { accid, ...noAccQuery } = to.query;
    return next({query: noAccQuery});
  }

  if (localStorage.getItem("authenticated") === "true") {
    if (parseInt(process.env.VUE_APP_TWO_FA_BYPASS) === 0) {
      if ((to.path === '/phone/validation' || to.path === '/phone/code/validation')
          && localStorage.getItem("authenticated2fa") === "true"
          && store.getters["user/user_info"].twoFaExpirationDate
          && new Date().getTime() < new Date(store.getters["user/user_info"].twoFaExpirationDate).getTime()
      ) {
        return next({ name: "Accounts" });
      }

      if (from.path !== '/phone/validation'
          && to.path !== '/phone/validation'
          && to.path !== '/phone/code/validation'
          && (localStorage.getItem("authenticated2fa") !== "true"
              || !store.getters["user/user_info"].twoFaExpirationDate
              || new Date().getTime() > new Date(store.getters["user/user_info"].twoFaExpirationDate).getTime())
      ) {
        if (from.path === '/phone/code/validation') {
          return next({ name: "PhoneCodeConfirm" });
        }

        return next({ name: "PhoneConfirm" });
      }
    }

    // check if users already logged in
    if (from.path === '/' && to.path === '/') {
      return next({ name: "Accounts" });
    }
  }

  if (localStorage.getItem("authenticated") === "false" || !localStorage.getItem("authenticated")) {
    if (to.fullPath !== undefined
        && to.name !== "Accounts"
        && to.name !== "Login"
        && to.name !== "Logout"
        && to.name !== "PhoneConfirm"
        && to.name !== "PhoneCodeConfirm"
        && to.path !== '/') {
      let date = new Date()
      date.setUTCHours(date.getUTCHours() + 3);
      const item = {
        value: to.fullPath,
        expiry: date.getTime(),
      }
      localStorage.setItem("redirectPage", JSON.stringify(item))
    } else {
      localStorage.removeItem("redirectPage");
    }

    if (to.name !== "Login") {
      return next({name: "Login"});
    }
  }

  const vuexAccount = store.getters["user/account"];

  if (to?.name === 'PluginVeevaConfig') {
    let selectedAccount = vuexAccount;
    if (selectedAccount && vuexAccount.accountId !== to.params.accountId) {
      selectedAccount = await store.dispatch('user/selectAccount', to.params.accountId);
      store.commit("snackbar/showMessage",
          { content: `Successfully selected account ${selectedAccount.account.name}`, color: "success" }
      );
    }

    let veevaAccountPluginId = null;

    selectedAccount.account.activePlugins.forEach(plugin => {
      if (plugin.plugin.componentName === 'AddPluginVeeva') {
        veevaAccountPluginId = plugin.id;
      }
    })

    if (veevaAccountPluginId) {
      return next({
        name: "AddPluginVeeva",
        params: {
          id: veevaAccountPluginId,
          installed: true,
        },
        query: {
          installed: true,
          code: to.query?.code,
          accid: to.params.accountId,
        },
      });
    }

    store.commit("snackbar/showMessage",
        { content: 'The Veeva plugin is not installed for this account.', color: "error" }
    );
    setTimeout(() => {
      this.$router.push({
        name: "Index",
      })
    }, 500);
  }

  // Check to see if we should pre-load an account
  const localStorageAccountId = JSON.parse(localStorage.getItem("account"))?.accountId;
  let vuexAccountId = vuexAccount ? vuexAccount.accountId : null;
  const queryAccountId = to?.query?.accid;

  if (localStorageAccountId || vuexAccountId || queryAccountId) {
    let accountToLoad = null;
    if (queryAccountId) {
      // We have an account ID in the query string - it takes priority
      accountToLoad = queryAccountId;
    } else if (localStorageAccountId) {
      // We have the previously selected account in local storage
      accountToLoad = localStorageAccountId;
    }

    if (accountToLoad && (!vuexAccountId || vuexAccountId !== accountToLoad)) {
      // Select account using the accountToLoad variable
      // Like we do starting line 540
      localStorage.removeItem("account");
      localStorage.removeItem("defaultDashboard");
      store.getters["user/account"].accountId = undefined;
      store.commit("user/update_selected_account", undefined);

      const selectedAccount = await store.dispatch('user/selectAccount', accountToLoad);
      store.commit("snackbar/showMessage",
        { content: `Successfully selected account ${selectedAccount.account.name}`, color: "success" }
      );
      vuexAccountId = store.getters["user/account"].accountId;
    }
  }

  // Handle routes that require being logged in
  if (to.meta.protected) {
    if (to.meta.account_needed
        && !vuexAccountId
        && to.name !== "Accounts"
        && to.name !== "PhoneConfirm"
    ) {
      // An account is needed to be loaded before accessing this route
      // Redirect the user to the account selection page since we don't know which one should be loaded
      return next({ name: "Accounts", query: { next: to.path } });
    }
    if (to.meta.private
        && to.meta.private.length > 0
        && !to.meta.private.some(role => store.getters['user/activeRoles'].includes(role))
    ) {
      // The user is trying to access a route that requires some roles
      // And the user does not have those roles
      // Redirect to the account selection page for now since they don't have access
      // TODO - maybe create a page for "Access Denied" or similar errors
      return next({ name: "Accounts", query: { next: to.path } });
    }
    if (vuexAccountId
        && !hasAccQuery(to)
        && to.name !== "Accounts"
    ) {
      // The user has selected an account but the selected account is not added to the query string
      // So we should make sure the router adds it
      return next({
        name: to.name,
        params: to.params,
        query: {
          ...to.query,
          accid: vuexAccountId,
        }
      });
    }
  }

  if (!hasAccQuery(to)
      && hasAccQuery(from)
      && to.name !== "Accounts"
  ) {
    // The route that the user came from contained an account ID in the query string
    // but the route that the user is going to does not contain it
    // So we should add the account ID from the origin route to the next one to keep it
    // in the query string
    return next({
      name: to.name,
      params: to.params,
      query: {
        ...to.query,
        accid: from.query.accid,
      }
    });
  } else {
    // Handle route as normal
    next()
  }
});

function hasAccQuery(route) {
  return !!route?.query?.accid;
}

export default router;
