import Vue from "vue";
import { v4 as uuidv4 } from "uuid";
import {
  checkForHorizontalSnap,
  checkForVerticalSnap,
  getOuterPositionForTransition,
  runAutomationHooks,
} from "@/sharedComponents/automation-calculations";
import Campaigns from "./api/Campaigns";

const getPreviousNodes = (previousEmailNode, nodes) => {
  if (
    previousEmailNode.node_type !== "AutoResendEmail" ||
    !previousEmailNode.config.previousEmailNodeUUID
  )
    return [previousEmailNode];

  return [
    previousEmailNode,
    ...getPreviousNodes(
      nodes[previousEmailNode.config.previousEmailNodeUUID],
      nodes
    ),
  ];
};

export const automation = {
  namespaced: true,
  state: {
    isShowCounts: false,
    isPreviewingEmail: false,
    emailImage: null,
    // The campaign details for the one being edited
    campaignBeingEdited: null,
    // The height of the automation container
    containerHeight: 0,
    // The width of the automation container
    containerWidth: 0,
    // Data used to build configuration modals/forms. Some of these are shared by multiple nodes
    configurationData: {
      // List of all the emailMessages
      emailMessages: null,
    },
    // Internal hooks that are called for things such as preCreateNode, postCreateTransition, postDeleteTransition, etc.
    hooks: {
      // Gets called before the transition is set in state
      preCreateTransition: {},
      // Gets called after the transition is deleted from state
      postDeleteTransition: {},
    },
    // The range around the center of a node that a draggable node can snap to
    horizontalSnapRange: 30,
    // Whether or not the current campaign is being saved
    isSavingCampaign: false,
    // All the nodes belonging to the automation campaign
    nodes: {},
    // The node being configured
    nodeBeingConfigured: null,
    // The node being dragged on to the canvas to create
    nodeBeingCreated: null,
    // The current node details of the node being dragged
    nodeBeingDragged: null,
    // The current node being hovered in the canvas
    nodeBeingHovered: null,
    // The node being selected on the canvas
    nodeBeingSelected: null,
    // The current scale of the canvas/main layer
    scale: 1,
    // The max value that the scale can be set to
    scaleMax: 1,
    // The min value that the scale can be set to
    scaleMin: 0.5,
    // How much to zoom in by
    scaleStep: 1.1,
    // The X direction the canvas has moved from the original position
    screenMovementX: 0,
    // The Y direction the canvas has moved from the original position
    screenMovementY: 0,
    // Show estimate/live stat numbers for the nodes
    showNodeStatistics: false,
    // The current transition being created
    transitionBeingCreated: null,
    // All the transitions belonging to the automation campaign
    transitions: {},
    // The range around the center of a node that a draggable node can snap to
    verticalSnapRange: 55,
  },
  getters: {
    getIsPreviewingEmail: (state) => state.isPreviewingEmail,
    getEmailCreative: (state) => state.emailImage,
    getEditingCampaign: (state) => state.campaignBeingEdited,
    getEditingCampaignName: (state) => state.campaignBeingEdited?.name,
    /**
     * Checks if the node being dragged can be snapped to another horizontally
     */
    canSnapHorizontally(state) {
      let nodeToSnap = state.nodeBeingCreated || state.nodeBeingDragged;

      if (nodeToSnap && !nodeToSnap.nuid) {
        nodeToSnap = null;
      }

      return nodeToSnap
        ? checkForHorizontalSnap(
            state.nodes,
            nodeToSnap,
            state.horizontalSnapRange,
            false
          )
        : false;
    },
    /**
     * Checks if the node being dragged can be snapped to another vertically
     */
    canSnapVertically(state) {
      let nodeToSnap = state.nodeBeingCreated || state.nodeBeingDragged;

      if (nodeToSnap && !nodeToSnap.nuid) {
        nodeToSnap = null;
      }

      return nodeToSnap
        ? checkForVerticalSnap(
            state.nodes,
            nodeToSnap,
            state.verticalSnapRange,
            false
          )
        : false;
    },
    isNodeBeingDragged(state) {
      const hasExistingNodeBeingDragged = !!state.nodeBeingDragged;
      const hasNodeBeingCreated =
        !!state.nodeBeingCreated && !!state.nodeBeingCreated.nuid;

      return hasExistingNodeBeingDragged || hasNodeBeingCreated;
    },
    getNodes(state) {
      return state.nodes;
    },
    getNodesForMainLayer(state) {
      let nodeIdsToFilterBy = [];

      if (state.transitionBeingCreated) {
        nodeIdsToFilterBy.push(state.transitionBeingCreated.startNode.nuid);
      }

      if (state.nodeBeingCreated && state.nodeBeingCreated.nuid) {
        nodeIdsToFilterBy.push(state.nodeBeingCreated.nuid);
      }

      if (state.nodeBeingHovered && state.nodeBeingHovered.nuid) {
        nodeIdsToFilterBy.push(state.nodeBeingHovered.nuid);
      }

      if (state.nodeBeingDragged && state.nodeBeingDragged.nuid) {
        nodeIdsToFilterBy.push(state.nodeBeingDragged.nuid);
      }
      return Object.keys(state.nodes)
        .filter((key) => !nodeIdsToFilterBy.includes(key))
        .reduce((obj, key) => {
          obj[key] = state.nodes[key];
          return obj;
        }, {});
    },
    getNodesForActionLayer(state) {
      let nodeIdsToFilterBy = [];

      if (state.transitionBeingCreated) {
        nodeIdsToFilterBy.push(state.transitionBeingCreated.startNode.nuid);
      }

      if (state.nodeBeingCreated && state.nodeBeingCreated.nuid) {
        nodeIdsToFilterBy.push(state.nodeBeingCreated.nuid);
      }

      if (state.nodeBeingHovered && state.nodeBeingHovered.nuid) {
        nodeIdsToFilterBy.push(state.nodeBeingHovered.nuid);
      }

      if (state.nodeBeingDragged && state.nodeBeingDragged.nuid) {
        nodeIdsToFilterBy.push(state.nodeBeingDragged.nuid);
      }

      return Object.keys(state.nodes)
        .filter((key) => nodeIdsToFilterBy.includes(key))
        .reduce((obj, key) => {
          obj[key] = state.nodes[key];
          return obj;
        }, {});
    },
    getHorizontalDragGuidePoints(state) {
      const nodeToGuide = state.nodeBeingCreated || state.nodeBeingDragged;
      return nodeToGuide
        ? [
            -10000,
            nodeToGuide.position.y + 60,
            10000,
            nodeToGuide.position.y + 60,
          ]
        : [];
    },
    getVerticalDragGuidePoints(state) {
      const nodeToGuide = state.nodeBeingCreated || state.nodeBeingDragged;
      return nodeToGuide
        ? [
            nodeToGuide.position.x + 92,
            -10000,
            nodeToGuide.position.x + 92,
            10000,
          ]
        : [];
    },
    getPointsForTransitionBeingCreated(state) {
      if (!state.transitionBeingCreated) {
        return [0, 0, 0, 0];
      }

      let target = state.transitionBeingCreated.targetPosition;

      if (state.transitionBeingCreated.targetNode) {
        target = getOuterPositionForTransition(
          {
            x: state.transitionBeingCreated.startNode.position.x,
            y: state.transitionBeingCreated.startNode.position.y,
          },
          {
            x: state.transitionBeingCreated.targetNode.position.x,
            y: state.transitionBeingCreated.targetNode.position.y,
          }
        );

        return [
          state.transitionBeingCreated.startNode.position.x + 92,
          state.transitionBeingCreated.startNode.position.y + 60,
          target.x + 92,
          target.y + 60,
        ];
      }

      return [
        state.transitionBeingCreated.startNode.position.x + 92,
        state.transitionBeingCreated.startNode.position.y + 60,
        target.x / state.scale + 92,
        target.y / state.scale + 60,
      ];
    },
    getStageWidth(state) {
      return state.containerWidth / state.scale;
    },
    getTransitionsForMainLayer(state) {
      let transitionIdsToFilterBy = [];
      if (state.nodeBeingDragged) {
        for (let tuid in state.nodeBeingDragged.transitions) {
          transitionIdsToFilterBy.push(tuid);
        }
      }

      return Object.keys(state.transitions)
        .reverse()
        .filter((key) => !transitionIdsToFilterBy.includes(key))
        .reduce((obj, key) => {
          obj[key] = state.transitions[key];
          return obj;
        }, {});
    },
    getTransitionsForActionLayer(state) {
      let transitionIdsToFilterBy = [];
      if (state.nodeBeingDragged) {
        for (let tuid in state.nodeBeingDragged.transitions) {
          transitionIdsToFilterBy.push(tuid);
        }
        for (let tuid in state.nodeBeingDragged.pre_transitions) {
          transitionIdsToFilterBy.push(tuid);
        }
      }

      return Object.keys(state.transitions)
        .reverse()
        .filter((key) => transitionIdsToFilterBy.includes(key))
        .reduce((obj, key) => {
          obj[key] = state.transitions[key];
          return obj;
        }, {});
    },
    isCampaignValid(state) {
      if (!state.nodes || Object.keys(state.nodes).length < 2) {
        return false;
      }

      for (let nuid in state.nodes) {
        if (!state.nodes[nuid].isValid) {
          return false;
        } else if (
          Object.keys(state.nodes[nuid].transitions).length === 0 &&
          Object.keys(state.nodes[nuid].pre_transitions).length === 0
        ) {
          return false;
        }
      }

      return true;
    },
  },
  mutations: {
    addHook(state, hookDetails) {
      Vue.set(
        state.hooks[hookDetails.hookKey],
        hookDetails.nuid,
        hookDetails.hookFunction
      );
    },
    beginSavingCampaign(state) {
      state.isSavingCampaign = true;
    },
    createNode(state) {
      const nodeBeingCreated = state.nodeBeingCreated;

      // Need to use Vue.set() in order for it to be reactive
      Vue.set(state.nodes, nodeBeingCreated.nuid, nodeBeingCreated);

      state.nodeBeingCreated = null;
    },
    createTransition(state, transitionToCreate) {
      const transitionBeingCreated =
        transitionToCreate || state.transitionBeingCreated;
      const uuid = transitionToCreate ? transitionToCreate.tuid : uuidv4();
      const startNodeNuid = transitionBeingCreated.startNode.nuid;
      const targetNodeNuid = transitionBeingCreated.targetNode.nuid;

      const transition = {
        next: targetNodeNuid,
        tuid: uuid,
        event: transitionBeingCreated.event,
      };

      transitionBeingCreated.tuid = uuid;
      transitionBeingCreated.transitionCount = 1;

      for (let tuid in state.nodes[startNodeNuid].transitions) {
        if (
          state.nodes[startNodeNuid].transitions[tuid].next === transition.next
        ) {
          transitionBeingCreated.transitionCount++;
        }
      }

      if (transitionBeingCreated.event) {
        transitionBeingCreated.text = Campaigns.getTransitionText(
          transitionBeingCreated
        );
      }

      // Need to use Vue.set() in order for it to be reactive
      Vue.set(state.nodes[startNodeNuid].transitions, uuid, transition);
      Vue.set(state.nodes[targetNodeNuid].pre_transitions, uuid, {
        prev: startNodeNuid,
        tuid: uuid,
        event: transitionBeingCreated.event,
      });
      Vue.set(state.transitions, uuid, transitionBeingCreated);

      state.transitionBeingCreated = null;
    },
    updateTransition(state, { transition: transitionId, event }) {
      state.transitions[transitionId].event = event;
    },
    deleteNode(state, nuid) {
      if (Object.keys(state.hooks).length === 0) {
        return;
      }

      for (let hookKey in state.hooks) {
        if (Object.keys(state.hooks[hookKey]).length === 0) {
          continue;
        }

        if (state.hooks[hookKey][nuid] !== undefined) {
          Vue.delete(state.hooks[hookKey], nuid);
        }
      }

      Vue.delete(state.nodes, nuid);
    },
    deleteTransition(state, transitionDetails) {
      const tuid = transitionDetails.tuid;
      if (!state.transitions[tuid]) {
        return;
      }

      const targetNode = state.transitions[tuid].targetNode;
      const sourceNode = state.transitions[tuid].startNode;
      for (let it_tuid in state.transitions) {
        let trans = state.transitions[it_tuid];
        if (
          trans.startNode.nuid === sourceNode.nuid &&
          trans.targetNode.nuid === targetNode.nuid
        ) {
          if (trans.transitionCount > 1) {
            trans.transitionCount--;
          }
        }
      }

      Vue.delete(state.nodes[sourceNode.nuid].transitions, tuid);
      Vue.delete(state.nodes[targetNode.nuid].pre_transitions, tuid);
      Vue.delete(state.transitions, tuid);
    },
    doneSavingCampaign(state) {
      state.isSavingCampaign = false;
    },
    draggedNodeToPosition(state, position) {
      if (state.nodeBeingDragged) {
        state.nodeBeingDragged.position = position;
      }
    },
    draggedNodeBeingCreatedToPosition(state, position) {
      if (state.nodeBeingCreated) {
        state.nodeBeingCreated.position = position;
      }
    },
    setCampaignBeingEdited(state, campaignDetails) {
      state.campaignBeingEdited = campaignDetails;
    },
    setCanvasMovement(state, position) {
      state.screenMovementX = position.x;
      state.screenMovementY = position.y;
    },
    setIdForCampaignBeingEdited(state, id) {
      state.campaignBeingEdited.id = id;
    },
    setNodesOnCampaignBeingEdited(state) {
      const nodes = [];

      for (let nuid in state.nodes) {
        const node = state.nodes[nuid];

        nodes.push({
          configuration: {
            config: node.config,
            node_type: node.node_type,
            nuid,
            position: node.position,
            statistics: node.statistics,
            transitions: node.transitions,
            type: node.type,
          },
          isRecurringTrigger:
            node.type === "Trigger" && node.config.condition !== "all",
        });
      }

      Vue.set(state.campaignBeingEdited, "draftConfiguration", nodes);
    },
    setFlushTransitions(state) {
      state.transitions = {};
    },
    setTransition(state, transitionToCreate) {
      state.transitionBeingCreated = transitionToCreate;

      state.nodeBeingHovered = null;
    },
    setTransitionTargetPosition(state, position) {
      state.transitionBeingCreated.targetPosition = {
        x: position.x - state.screenMovementX - 92,
        y: position.y - state.screenMovementY - 68,
      };
    },
    setConfigurationData(state, configurationDetails) {
      state.configurationData[configurationDetails.key] =
        configurationDetails.value;
    },
    setContainerHeight(state, height) {
      state.containerHeight = height;
    },
    setContainerWidth(state, width) {
      state.containerWidth = width;
    },
    setPreviewShownForNode(state, previewDetails) {
      Vue.set(
        state.nodes[previewDetails.nuid].ui,
        "previewImageShown",
        previewDetails.previewIndex
      );
    },
    toggleNodeStatistics(state) {
      state.showNodeStatistics = !state.showNodeStatistics;
    },
    setNodes(state, nodes) {
      state.nodes = { ...nodes };
    },
    setNuidForNodeBeingCreated(state, nuid) {
      Vue.set(state.nodeBeingCreated, "nuid", nuid);

      state.nodeBeingCreated.nuid = nuid;
    },
    setTargetNodeForTransitionBeingCreated(state, targetNuid) {
      if (
        targetNuid &&
        targetNuid !== state.transitionBeingCreated.startNode.nuid
      ) {
        state.transitionBeingCreated.targetNode = state.nodes[targetNuid];
      } else {
        state.transitionBeingCreated.targetNode = null;
      }
    },
    setNodeBeingConfigured(state, nuid) {
      if (nuid && state.nodes[nuid]) {
        state.nodeBeingConfigured = state.nodes[nuid];
      } else {
        state.nodeBeingConfigured = null;
      }
    },
    setNodeBeingHovered(state, nuid) {
      if (nuid && state.nodes[nuid]) {
        state.nodeBeingHovered = state.nodes[nuid];
      } else {
        state.nodeBeingHovered = null;
      }
    },
    setSelectedNode(state, nuid) {
      if (nuid && state.nodes[nuid]) {
        state.nodeBeingSelected = state.nodes[nuid];
      } else {
        state.nodeBeingSelected = null;
      }
    },
    startCreatingNode(state, nodeTemplate) {
      state.nodeBeingCreated = nodeTemplate;
    },
    startDraggingNode(state, nuid) {
      state.nodeBeingDragged = state.nodes[nuid];
    },
    stopCreatingNode(state) {
      state.nodeBeingCreated = null;
    },
    stopDraggingNode(state) {
      const nuid = state.nodeBeingDragged.nuid;
      state.nodes[nuid].position = { ...state.nodeBeingDragged.position };
      state.nodeBeingDragged = null;
    },
    togglePreviewStatusForNode(state, nuid) {
      const currentStatus = state.nodes[nuid].ui.isPreviewVisible || false;
      Vue.set(state.nodes[nuid].ui, "isPreviewVisible", !currentStatus);
    },
    updateNode(state, nodeDetails) {
      const nuid = nodeDetails.nuid;
      const node = state.nodes[nuid];

      if (node) {
        state.nodes[nuid] = { ...node, ...nodeDetails };
      }
    },
    zoomIn(state) {
      let zoomedScale = state.scale * state.scaleStep;
      zoomedScale = zoomedScale < state.scaleMax ? zoomedScale : state.scaleMax;
      state.scale = zoomedScale;
    },
    zoomOut(state) {
      let zoomedScale = state.scale / state.scaleStep;
      zoomedScale = zoomedScale > state.scaleMin ? zoomedScale : state.scaleMin;
      state.scale = zoomedScale;
    },
    toggleCounts(state, defaultValue = true) {
      state.isShowCounts = !defaultValue ? defaultValue : !state.isShowCounts;
    },
  },
  actions: {
    canvasMoved({ commit }, position) {
      commit("setCanvasMovement", position);
    },
    async configureNode({ commit, state, dispatch }, nodeConfiguration) {
      nodeConfiguration.node_type = state.nodeBeingConfigured.node_type;
      Campaigns.validateNode(nodeConfiguration);
      nodeConfiguration.isValid = true;
      await dispatch("updateNode", nodeConfiguration);
      commit("setNodeBeingConfigured");
    },
    createNode({ commit, state }) {
      const verticalSnappableNode = checkForVerticalSnap(
        state.nodes,
        state.nodeBeingCreated,
        state.verticalSnapRange,
        true
      );
      const horizontalSnappableNode = checkForHorizontalSnap(
        state.nodes,
        state.nodeBeingCreated,
        state.horizontalSnapRange,
        true
      );

      if (verticalSnappableNode || horizontalSnappableNode) {
        commit("draggedNodeBeingCreatedToPosition", {
          x: verticalSnappableNode
            ? verticalSnappableNode.position.x
            : state.nodeBeingCreated.position.x,
          y: horizontalSnappableNode
            ? horizontalSnappableNode.position.y
            : state.nodeBeingCreated.position.y,
        });
      }
      const nodeBeingCreated = state.nodeBeingCreated;
      const nuid = state.nodeBeingCreated.nuid;
      const nodeDetails = Campaigns.getNodeTemplateForType(
        nodeBeingCreated.type,
        nodeBeingCreated.node_type
      );

      commit("createNode");

      if (
        nodeDetails &&
        nodeDetails.hooks &&
        Object.keys(nodeDetails.hooks).length > 0
      ) {
        for (let hookKey in nodeDetails.hooks) {
          commit("addHook", {
            hookKey,
            nuid,
            hookFunction: nodeDetails.hooks[hookKey],
          });
        }
      }

      return nodeBeingCreated;
    },
    creatingNodeInCanvas({ commit }) {
      const nuid = uuidv4();
      commit("setNuidForNodeBeingCreated", nuid);
    },
    createTransition({ commit, state }) {
      const transitionCreated = state.transitionBeingCreated;

      if (!transitionCreated.targetNode) {
        commit("setTransition", null);

        return transitionCreated;
      }

      const errors = Campaigns.validateTransition(
        transitionCreated,
        state.transitions,
        state.nodes
      );
      if (errors) {
        commit("setTransition", null);
        return;
      }

      if (Object.keys(state.hooks.preCreateTransition).length > 0) {
        runAutomationHooks(
          {
            nodes: state.nodes,
            transitions: state.transitions,
            hooks: state.hooks.preCreateTransition,
            commit,
          },
          [transitionCreated],
          () => {
            commit("createTransition");
            return transitionCreated;
          },
          () => {
            commit("setTransition", null);
          }
        );
      } else {
        commit("createTransition");
      }
    },
    deleteNode({ commit, state }, nuidToDelete) {
      return new Promise((resolve, reject) => {
        if (state.nodes[nuidToDelete].transitions) {
          for (let tuid in state.nodes[nuidToDelete].transitions) {
            commit("deleteTransition", { tuid, nuid: nuidToDelete });
          }
        }

        for (let nuid in state.nodes) {
          if (state.nodes[nuid].transitions) {
            for (let tuid in state.nodes[nuid].transitions) {
              if (state.nodes[nuid].transitions[tuid].next === nuidToDelete) {
                commit("deleteTransition", { tuid, nuid });
              }
            }
          }
        }

        if (Object.keys(state.hooks.postDeleteTransition).length > 0) {
          runAutomationHooks(
            {
              nodes: state.nodes,
              transitions: state.transitions,
              hooks: state.hooks.postDeleteTransition,
              commit,
            },
            [],
            () => {
              commit("deleteNode", nuidToDelete);

              if (state.nodeBeingSelected.nuid === nuidToDelete) {
                commit("setSelectedNode");
              }

              resolve();
            },
            () => {
              reject();
            }
          );
        } else {
          commit("deleteNode", nuidToDelete);

          if (
            state.nodeBeingSelected &&
            state.nodeBeingSelected.nuid === nuidToDelete
          ) {
            commit("setSelectedNode");
          }

          resolve();
        }
      });
    },
    deleteTransition({ commit, state }, transitionToDelete) {
      return new Promise((resolve, reject) => {
        commit("deleteTransition", transitionToDelete);

        if (Object.keys(state.hooks.postDeleteTransition).length > 0) {
          runAutomationHooks(
            {
              nodes: state.nodes,
              transitions: state.transitions,
              hooks: state.hooks.postDeleteTransition,
              commit,
            },
            [],
            () => {
              resolve();
            },
            () => {
              reject();
            }
          );
        }

        resolve();
      });
    },
    draggedNodeToPosition({ commit }, position) {
      commit("draggedNodeToPosition", position);
    },
    draggedNodeBeingCreatedToPosition({ commit, state }, position) {
      return new Promise((resolve) => {
        commit("draggedNodeBeingCreatedToPosition", {
          x: (position.x - state.screenMovementX) / state.scale,
          y: (position.y - state.screenMovementY) / state.scale,
        });

        resolve();
      });
    },
    getPreviousNodesForAutoResendAction({ state }, nuid) {
      const autoResendAction = state.nodes[nuid] || null;
      if (!autoResendAction || !autoResendAction.config.previousEmailNodeUUID)
        return [];

      return getPreviousNodes(
        state.nodes[autoResendAction.config.previousEmailNodeUUID],
        state.nodes
      ).reverse();
    },
    hoverNode({ commit }, nuid) {
      return new Promise((resolve) => {
        commit("setNodeBeingHovered", nuid);

        resolve();
      });
    },
    leavingCanvasWithCreatedNode({ commit }) {
      return new Promise((resolve) => {
        commit("setNuidForNodeBeingCreated", null);

        resolve();
      });
    },
    async getCampaignFromLines({ rootGetters }) {
      return this._vm.$rest.fromlines.get_collection({
        ignorePagination: true
      }).then(data => {
        const fromLines = data?.data?.items ?? [];

        if (rootGetters['user/isPluginActive']('AddPluginRepManagement')) {
          fromLines.push({
            id: 'SEND_FROM_REP',
            fromName: "Send from Contact's Rep",
            fromEmail: '',
          });
        }

        return fromLines;
      });
    },
    async loadCampaignData({ state, commit }, campaignId) {
      let success = await this._vm.$rest.campaign.get_resource(campaignId);
      // TODO: remove facade implementation later
      let resp = {
        ...success.data,
        draftConfiguration: {
          nodes: {},
        },
      };
      if (success.data.draftConfiguration.nodes) {
        Object.keys(success.data.draftConfiguration.nodes).forEach((index) => {
          let idx = index !== "undefined" ? index : uuidv4();
          resp.draftConfiguration.nodes[idx] = {
            ...success.data.draftConfiguration.nodes[index],
            nuid: idx,
          };

          if (!resp.draftConfiguration?.nodes[idx]?.config?.name) {
            // Try to handle old formatting
            resp.draftConfiguration.nodes[idx].config.name =
              resp.draftConfiguration.nodes[idx].name;
          }
        });
      }

      // end of nodes  facade
      let nodes = {};
      if (success.data) {
        commit("setCampaignBeingEdited", { ...resp });

        if (resp.draftConfiguration.nodes) {
          nodes = { ...resp.draftConfiguration.nodes };
        }
      }
      if (nodes && Object.keys(nodes).length > 0) {
        const transitions = {};

        for (let nuid in nodes) {
          const node = nodes[nuid];
          node.isNew = true;
          const nodeDetails = Campaigns.getNodeTemplateForType(
            node.type,
            node.nodeType
          );
          try {
            Campaigns.validateNode({
              node_type: node.node_type,
              config: { ...node.config },
            });
            node.isValid = true;
          } catch (e) {
            console.error(e);
          } finally {
            nodes[nuid] = Object.assign(nodeDetails, node);
            if (
              nodeDetails.transitions &&
              Object.keys(nodeDetails.transitions).length > 0
            ) {
              for (let tuid in nodeDetails.transitions) {
                const transition = nodeDetails.transitions[tuid];
                transition.startNuid = nuid;
                transition.tuid = tuid;
                transitions[tuid] = transition;
              }
            }

            // Always set this to an empty object
            // If node has transition, they will be added back below as transitions are created
            // If node doesn't, then it'll show as an array which will cause problems if not an object
            nodes[nuid].transitions = {};

            if (
              nodeDetails &&
              nodeDetails.hooks &&
              Object.keys(nodeDetails.hooks).length > 0
            ) {
              for (let hookKey in nodeDetails.hooks) {
                commit("addHook", {
                  hookKey,
                  nuid,
                  hookFunction: nodeDetails.hooks[hookKey],
                });
              }
            }
          }
        }
        commit("setNodeBeingHovered");
        commit("setNodes", nodes);
        commit("setNodeBeingHovered");
        if (transitions) {
          for (let tuid in transitions) {
            transitions[tuid].startNode =
              state.nodes[transitions[tuid].startNuid];
            transitions[tuid].targetNode = state.nodes[transitions[tuid].next];
            commit("createTransition", transitions[tuid]);
          }
        }
      }
    },
    loadConfigurationData(
      { commit },
      { key: configurationKey, campaignStatus }
    ) {
      let showArchived = false;
      if (campaignStatus) {
        showArchived = !(
          campaignStatus == "draft" || campaignStatus == "scheduled"
        );
      }

      return new Promise((resolve) => {
        let configurationData = null;
        switch (configurationKey) {
          case "segments":
            this._vm.$rest.segment
              .get_collection({
                sort: ["name:asc"],
                isArchived: showArchived ? null : false,
                ignorePagination: true,
              })
              .then((segments) => {
                configurationData = segments.data.items;
                if (showArchived) {
                  configurationData = configurationData.map((segment) => ({
                    ...segment,
                    name: segment.isArchived
                      ? segment.name + " [Archived]"
                      : segment.name,
                  }));
                }

                commit("setConfigurationData", {
                  key: configurationKey,
                  value: configurationData,
                });

                resolve(configurationData);
              });
            break;

          default:
            resolve(null);
            break;
        }
      });
    },
    resizeContainer({ commit }, properties) {
      commit("setContainerWidth", properties.width);
      commit("setContainerHeight", properties.height);
    },
    saveCampaign({ commit, state }) {
      return new Promise((resolve, reject) => {
        commit("beginSavingCampaign");

        commit("setNodesOnCampaignBeingEdited");

        Campaigns.saveCampaign(state.campaignBeingEdited)
          .then((success) => {
            commit("doneSavingCampaign");

            const campaign = success.data;

            commit("setIdForCampaignBeingEdited", campaign.id);

            resolve(campaign);
          })
          .catch((appError) => {
            commit("doneSavingCampaign");

            if (appError.isUnauthorized()) {
              commit("auth/clearAuthData", { root: true });
              this.$router.push("/login");
              return;
            }

            reject(appError);
          });
      });
    },
    selectNode({ commit }, nuid) {
      return new Promise((resolve) => {
        commit("setSelectedNode", nuid);

        resolve();
      });
    },
    setPreviewShownForNode({ commit }, previewDetails) {
      return new Promise((resolve) => {
        commit("setPreviewShownForNode", previewDetails);

        resolve();
      });
    },
    setTargetPositionForTransition({ commit }, position) {
      commit("setTransitionTargetPosition", position);
    },
    setTargetNodeForTransition({ commit }, targetNuid) {
      commit("setTargetNodeForTransitionBeingCreated", targetNuid);
    },
    startDraggingNode({ commit }, nuid) {
      return new Promise((resolve) => {
        commit("startDraggingNode", nuid);

        resolve();
      });
    },
    startCampaign({ commit }, campaignName) {
      return new Promise((resolve) => {
        const campaignTemplate = Campaigns.getAutomationCampaignTemplate();
        campaignTemplate.name = campaignName;

        commit("setCampaignBeingEdited", campaignTemplate);

        resolve(campaignTemplate);
      });
    },
    startConfiguringNode({ commit }, nuid) {
      commit("setNodeBeingConfigured", nuid);
    },
    startCreatingNode({ commit }, nodeDetails) {
      return new Promise((resolve) => {
        const nodeTemplate = Campaigns.getNodeTemplateForType(
          nodeDetails.type,
          nodeDetails.node_type
        );
        nodeTemplate.position = nodeDetails.startPosition;

        commit("startCreatingNode", nodeTemplate);

        resolve();
      });
    },
    startTransitionFromNode({ commit, state }, transitionDetails) {
      const startNode = state.nodes[transitionDetails.nuid];
      let event = "";
      if (transitionDetails.event) {
        event = transitionDetails.event;
      } else {
        event =
          typeof startNode.ui.transitionEvents === "object"
            ? Object.keys(startNode.ui.transitionEvents)[0]
            : startNode.ui.transitionEvents;
      }

      commit("setTransition", {
        startNode,
        targetNode: null,
        targetPosition: startNode.position,
        event,
        tuid: null,
      });
    },
    stopConfiguringNode({ commit }) {
      commit("setNodeBeingConfigured");
      commit("setSelectedNode");
    },
    stopCreatingNode({ commit }) {
      return new Promise((resolve) => {
        commit("stopCreatingNode");
        resolve();
      });
    },
    stopDraggingNode({ commit, state }) {
      const verticalSnappableNode = checkForVerticalSnap(
        state.nodes,
        state.nodeBeingDragged,
        state.verticalSnapRange,
        true
      );
      const horizontalSnappableNode = checkForHorizontalSnap(
        state.nodes,
        state.nodeBeingDragged,
        state.horizontalSnapRange,
        true
      );
      if (verticalSnappableNode || horizontalSnappableNode) {
        let position = {};

        position.x = verticalSnappableNode
          ? verticalSnappableNode.position.x
          : state.nodeBeingDragged.position.x;
        position.y = horizontalSnappableNode
          ? horizontalSnappableNode.position.y
          : state.nodeBeingDragged.position.y;

        commit("draggedNodeToPosition", position);
      }

      commit("stopDraggingNode");
    },
    stopHoveringNode({ commit }) {
      return new Promise((resolve) => {
        commit("setNodeBeingHovered", null);

        resolve();
      });
    },
    toggleNodeStatistics({ commit }) {
      return new Promise((resolve) => {
        commit("toggleNodeStatistics");

        resolve();
      });
    },
    togglePreviewStatusForNode({ commit }, nuid) {
      return new Promise((resolve) => {
        commit("togglePreviewStatusForNode", nuid);

        resolve();
      });
    },
    unselectNode({ commit }) {
      return new Promise((resolve) => {
        commit("setSelectedNode");

        resolve();
      });
    },
    updateNode({ commit }, nodeDetails) {
      return new Promise((resolve) => {
        commit("updateNode", nodeDetails);
        resolve();
      });
    },
    zoom({ commit, state }, isZoomIn) {
      return new Promise((resolve) => {
        if (isZoomIn && state.scale < state.scaleMax) {
          commit("zoomIn");
          resolve();
        } else if (!isZoomIn && state.scale > state.scaleMin) {
          commit("zoomOut");
          resolve();
        }
        resolve();
      });
    },
    openPreviewEmail({ state }, imageUrl) {
      state.isPreviewingEmail = true;
      state.emailImage = imageUrl;
    },
    closePreviewEmail({ state }) {
      state.isPreviewingEmail = false;
    },
  },
};
