import axios from "axios";
import default_icons from "@/assets/icons/automated/default_icons";
import { transitionCreatesInfiniteLoop } from "@/sharedComponents/automation-calculations";
import store from "@/store/index";

const CREATED_TRANSACTION_ID = 'test';

const checkIfValid = (previousEmailNode, nodes) => {
  if (previousEmailNode.node_type === "AutoResendEmail"
      && previousEmailNode.config.previousEmailNodeUUID
  ) {
    return checkIfValid(
      nodes[previousEmailNode.config.previousEmailNodeUUID],
      nodes,
    );
  }

  return previousEmailNode.isValid;
};

const getPreviousEmailNodes = (
  allNodes,
  previousTransitions,
  transitionBeingCreated,
  nodesChecked = []
) => {
  const emailNodeTypes = [
    "SendEmail",
    "SendSplitTestEmail",
    "AutoResendEmail"
  ];

  let previousEmailNodes = [];

  for (let i in previousTransitions) {
    const previousTransition = previousTransitions[i];

    const startNode = allNodes[previousTransition.prev] ?? allNodes[previousTransition.startNode.nuid] ?? null;
    if (!startNode || nodesChecked.find(item => item.nuid === startNode.nuid)) {
      continue;
    }

    nodesChecked.push(startNode);

    if ((emailNodeTypes.includes(startNode.node_type) || emailNodeTypes.includes(startNode.nodeType))
        && !previousEmailNodes.find(item => item.nuid === startNode.nuid)
    ) {
      previousEmailNodes.push(startNode);
      break;
    }

    if (startNode.pre_transitions !== undefined &&
      Object.keys(startNode.pre_transitions).length > 0
    ) {
      const transitionsToCheckNext = Object.assign({}, startNode.pre_transitions);

      if (!!transitionBeingCreated && transitionBeingCreated.targetNode.nuid !== startNode.nuid) {
        transitionsToCheckNext[CREATED_TRANSACTION_ID] = transitionBeingCreated;
      }

      const previous = getPreviousEmailNodes(allNodes, transitionsToCheckNext, transitionBeingCreated, nodesChecked);
      if (previous.length > 0) {
        previousEmailNodes = previousEmailNodes.concat(previous);
      }
    }
  }

  return previousEmailNodes;
};

const getPreviousTransitionPathsForAutoResendNode = (
    allNodes,
    previousTransitions,
    transitionBeingCreated,
    emailNodeTypes,
    nodesChecked = []
) => {
  let transitionPaths = [];

  for (let i in previousTransitions) {
    const previousTransition = previousTransitions[i];

    const startNode = allNodes[previousTransition.prev] ?? allNodes[previousTransition.startNode.nuid] ?? null;
    if (!startNode) {
      continue;
    }

    // Start the transition path for this transition
    const transitionPath = {
      tuids: [i],
      nuids: [startNode.nuid],
      emailNode: null,
    };

    if (nodesChecked[startNode.nuid]) {
      // We've checked this node before, let's just combine the data we have for that node
      // and add it to this transition path
      if (nodesChecked[startNode.nuid].paths.length > 0) {
        for (let j in nodesChecked[startNode.nuid].paths) {
          const previousPath = nodesChecked[startNode.nuid].paths[j];

          transitionPaths.push(Object.assign({}, {
            tuids: [i].concat(previousPath.tuids),
            nuids: [startNode.nuid].concat(previousPath.nuids),
            emailNode: previousPath.emailNode,
          }));
        }
      } else {
        transitionPaths.push(Object.assign({}, transitionPath));
      }

      continue;
    }

    if (emailNodeTypes.includes(startNode.node_type) || emailNodeTypes.includes(startNode.nodeType)) {
      // The start node is an email node, we don't need to check that nodes previous transitions
      transitionPath.emailNode = startNode;
      transitionPaths.push(Object.assign({}, transitionPath));

      continue;
    }

    // The start node is not an email node and has previous transitions
    // So we need to repeat this process for those transitions
    if (startNode.pre_transitions === undefined
        || startNode.pre_transitions === null
        || Object.keys(startNode.pre_transitions).length === 0
    ) {
      startNode.pre_transitions = {};
    }

    const transitionsToCheckNext = Object.assign({}, startNode.pre_transitions);

    // If the transition being created is attaching to this node we're currently checking
    // Then add it to the list of previous transitions so we can add it to the transition paths
    if (!!transitionBeingCreated && transitionBeingCreated.targetNode.nuid === startNode.nuid) {
      transitionsToCheckNext[CREATED_TRANSACTION_ID] = transitionBeingCreated;
    }

    if (Object.keys(transitionsToCheckNext).length === 0) {
      // This start node doesn't have any transitions, and it's not an email node so we can end this path here
      transitionPaths.push(Object.assign({}, transitionPath));

      nodesChecked[startNode.nuid] = {
        paths: [],
      };

      continue;
    }

    const previousPaths = getPreviousTransitionPathsForAutoResendNode(
        allNodes,
        transitionsToCheckNext,
        transitionBeingCreated,
        emailNodeTypes,
        nodesChecked
    );

    nodesChecked[startNode.nuid] = {
      paths: previousPaths,
    };
    if (previousPaths.length === 0) {
      continue;
    }

    for (let j in nodesChecked[startNode.nuid].paths) {
      const previousPath = nodesChecked[startNode.nuid].paths[j];

      transitionPaths.push(Object.assign({}, {
        tuids: [i].concat(previousPath.tuids),
        nuids: [startNode.nuid].concat(previousPath.nuids),
        emailNode: previousPath.emailNode,
      }));
    }
  }

  return transitionPaths;
};

export default {
  getNodeTemplatesByType() {
    const template = {
      isNew: true,
      isValid: false,
      isVisible: true,
      transitions: {},
      position: {
        x: 0,
        y: 0
      },
      pre_transitions: {},
      statistics: {
        entered: null,
        processing: null,
        completed: null,
        failed: null,
      }
    };

    return {
      Trigger: {
        Segment: Object.assign(
          {
            type: "Trigger",
            node_type: "Segment",
            config: {
              label: "",
              segmentId: null,
              condition: "all"
            },
            ui: {
              color: "#204673",
              icons: [default_icons.Segment, default_icons.SegmentWhite],
              label: "Segment Trigger"
            }
          },
          template
        )
      },
      Action: {
        AddContactToSegment: Object.assign(
          {
            type: "Action",
            node_type: "AddContactToSegment",
            config: {
              label: "",
              segmentId: null
            },
            ui: {
              color: "#387e5e",
              icons: [
                default_icons.AddToSegment,
                default_icons.AddToSegmentWhite
              ],
              label: "Add to Segment",
              transitionEvents: {
                added: "Added",
                excluded: "Excluded"
              }
            }
          },
          template
        ),
        AddDelay: Object.assign(
          {
            type: "Action",
            node_type: "AddDelay",
            config: {
              label: "",
              numberOfUnits: 1,
              unitOfTime: "day",
              type: "standard",
              specificDateTime: null,
              specificTimezone: null
            },
            ui: {
              color: "#353535",
              icons: [default_icons.AddDelay, default_icons.AddDelayWhite],
              label: "Add Delay",
              transitionEvents: "Delayed"
            }
          },
          template
        ),
        AutoResendEmail: Object.assign(
          {
            type: "Action",
            node_type: "AutoResendEmail",
            config: {
              label: "",
              previousEmailNodeUUID: null,
              rootEmailNodeUUID: null,
              main_node_type: null,
              newSubject: null,
              newPreviewText: null
            },
            ui: {
              color: "#4e98f7",
              icons: [
                default_icons.ResendEmail,
                default_icons.ResendEmailWhite
              ],
              label: "Auto Resend Email",
              transitionEvents: {
                sent: "Sent",
                previously_opened: "Previously Opened"
              }
            },
            hooks: {
              preCreateTransition: (
                nuid,
                allNodes,
                transitions,
                transitionBeingCreated
              ) => {
                return new Promise((resolve, reject) => {
                  // Get transitions connecting to this node
                  const transitionsConnectedToThisNode = Object.keys(transitions)
                    .filter(key => transitions[key].targetNode.nuid === nuid)
                    .reduce((obj, key) => {
                      obj[key] = {
                        tuid: key,
                        prev: transitions[key].startNode.nuid,
                        event: transitions[key].event,
                      };
                      return obj;
                    }, {});

                  // Make a copy
                  const transitionsToCheck = Object.assign({}, transitionsConnectedToThisNode);

                  if (transitionBeingCreated.targetNode.nuid === nuid) {
                    transitionsToCheck[CREATED_TRANSACTION_ID] = {
                      tuid: CREATED_TRANSACTION_ID,
                      prev: transitionBeingCreated.startNode.nuid,
                      event: transitionBeingCreated.event,
                    };
                  }

                  if (Object.keys(transitionsToCheck).length === 0) {
                    // No transitions are connected to this node
                    // AND the transition being created is not connecting to this node
                    resolve([]);
                    return;
                  }

                  const transitionPaths = getPreviousTransitionPathsForAutoResendNode(
                      allNodes,
                      transitionsToCheck,
                      transitionsToCheck[CREATED_TRANSACTION_ID] ? null : transitionBeingCreated,
                      [
                        "SendEmail",
                        "SendSplitTestEmail",
                        "AutoResendEmail"
                      ],
                  );

                  let hasPathWithoutEmailNode = false;
                  const emailNodes = {};
                  for (let i in transitionPaths) {
                    const path = transitionPaths[i];

                    if (path.emailNode === null) {
                      hasPathWithoutEmailNode = true;
                      continue;
                    }

                    if (emailNodes[path.emailNode.nuid]) {
                      continue;
                    }

                    emailNodes[path.emailNode.nuid] = path.emailNode;
                  }

                  const emailCount = Object.keys(emailNodes).length;
                  if (emailCount === 0) {
                    reject("An Auto Resend Email action must have ONE email sending action before it");

                    return;
                  }

                  if (emailCount > 1) {
                    reject("An Auto Resend Email action can only be connected to ONE email sending action");

                    return;
                  }

                  if (hasPathWithoutEmailNode) {
                    reject("This connection would cause an Auto Resend Email action to have a connection path without an email sending action.");

                    return;
                  }

                  const previousEmailNode = emailNodes[Object.keys(emailNodes)[0]];

                  let rootEmailNodeUUID = previousEmailNode.nuid;
                  let rootEmailNodeType = previousEmailNode.nodeType ?? previousEmailNode.node_type ?? null;

                  if ((previousEmailNode.nodeType ?? previousEmailNode.node_type) === 'AutoResendEmail') {
                    const transitionPaths = getPreviousTransitionPathsForAutoResendNode(
                      allNodes,
                      transitionsToCheck,
                      transitionsToCheck[CREATED_TRANSACTION_ID] ? null : transitionBeingCreated,
                      [
                        "SendEmail",
                        "SendSplitTestEmail"
                      ],
                    );

                    if (transitionPaths && transitionPaths.length > 0 && transitionPaths[0].emailNode) {
                      const rootEmailNode = transitionPaths[0].emailNode;

                      rootEmailNodeUUID = rootEmailNode.nuid;
                      rootEmailNodeType = rootEmailNode.nodeType ?? rootEmailNode.node_type ?? null;
                    }
                  }

                  resolve([
                    {
                      mutation: "updateNode",
                      payload: {
                        nuid: nuid,
                        isValid: checkIfValid(previousEmailNode, allNodes),
                        config: Object.assign(
                          Object.assign({}, allNodes[nuid]?.config),
                          {
                            previousEmailNodeUUID: previousEmailNode.nuid,
                            rootEmailNodeUUID: rootEmailNodeUUID,
                            main_node_type: rootEmailNodeType,
                          }
                        )
                      }
                    }
                  ]);
                });
              },
              postDeleteTransition: (nuid, nodes, transitions) => {
                return new Promise(resolve => {
                  const transitionsConnectedToThisNode = Object.keys(transitions)
                    .filter(key => transitions[key].targetNode.nuid === nuid)
                    .reduce((obj, key) => {
                      obj[key] = {
                        tuid: key,
                        prev: transitions[key].startNode.nuid,
                        event: transitions[key].event,
                      };
                      return obj;
                    }, {});

                  // Make a copy
                  const transitionsToCheck = Object.assign({}, transitionsConnectedToThisNode);

                  const transitionPaths = getPreviousTransitionPathsForAutoResendNode(
                      nodes,
                      transitionsToCheck,
                      null,
                      [
                        "SendEmail",
                        "SendSplitTestEmail",
                        "AutoResendEmail"
                      ],
                  );

                  let hasEmailNodeInPath = transitionPaths.length > 0;

                  for (let i in transitionPaths) {
                    const path = transitionPaths[i];

                    if (path.emailNode === null) {
                      hasEmailNodeInPath = false;
                      break;
                    }
                  }

                  if (hasEmailNodeInPath) {
                    resolve([]);

                    return;
                  }

                  const mutations = [{
                    mutation: "updateNode",
                    payload: {
                      nuid: nuid,
                      isValid: false,
                      config: Object.assign(
                        Object.assign({}, nodes[nuid]?.config),
                        {
                          previousEmailNodeUUID: null,
                          rootEmailNodeUUID: null,
                          main_node_type: null,
                          newSubject: null,
                          newPreviewText: null
                        }
                      )
                    }
                  }];

                  for (let i in transitionPaths) {
                    const transition = transitionPaths[i];
                    const firstTuid = transition.tuids[0];

                    mutations.push({
                      mutation: "deleteTransition",
                      payload: Object.assign({}, transitions[firstTuid]),
                    });
                  }

                  resolve(mutations);
                });
              }
            }
          },
          template
        ),
        Eject: Object.assign(
          {
            type: "Action",
            node_type: "Eject",
            config: {
              label: "Eject From Campaign"
            },
            ui: {
              color: "#c6c6c6",
              icons: [default_icons.Eject, default_icons.EjectWhite],
              label: "Eject from Campaign",
              showConfigurationLabel: true
            }
          },
          template
        ),
        RemoveContactFromSegment: Object.assign(
          {
            type: "Action",
            node_type: "RemoveContactFromSegment",
            config: {
              label: "",
              segmentId: null
            },
            ui: {
              color: "#ad2d25",
              icons: [
                default_icons.RemoveFromSegment,
                default_icons.RemoveFromSegmentWhite
              ],
              label: "Remove from Segment",
              transitionEvents: "Removed"
            }
          },
          template
        ),
        SendDirectMail: Object.assign(
          {},
          template,
            {
            type: "Action",
            node_type: "SendDirectMail",
            isVisible: store.getters['user/directMailAutomationVendors'].length > 0,
            config: {
              dbNodeID: null,
              label: "",
              creativeId: null,
              vendor: null,
            },
            ui: {
              color: "#996ac0",
              icons: [default_icons.MailPurple, default_icons.MailWhite],
              label: "Send Direct Mail",
              transitionEvents: "Mailed"
            }
          },
        ),
        SendEmail: Object.assign(
          {
            type: "Action",
            node_type: "SendEmail",
            config: {
              dbNodeID: null,
              label: "",
              creativeId: null,
              fromLineId: null,
              subject: null,
              previewText: null,
              useBestDay: false
            },
            ui: {
              color: "#0fafd6",
              icons: [default_icons.Mail, default_icons.MailWhite],
              label: "Send Email",
              transitionEvents: "Sent"
            }
          },
          template
        ),
        SendSms: Object.assign(
          {},
          template,
          {
            type: "Action",
            node_type: "SendSms",
            isVisible: store.getters['user/smsAutomationVendors'].length > 0,
            config: {
              dbNodeID: null,
              label: "",
              messageBody: "",
              vendor: null,
            },
            ui: {
              color: "#38e08f",
              icons: [default_icons.ChatGreen, default_icons.ChatWhite],
              label: "Send SMS",
              transitionEvents: "Sent"
            }
          },
        ),
        SendSplitTestEmail: Object.assign(
          {
            type: "Action",
            node_type: "SendSplitTestEmail",
            config: {
              type: "AB",
              label: "",
              fromLineId: null,
              testType: "subject",
              testSizePercent: 15,
              winnerMetric: "open",
              sendWinningEmail: 24,
              analysisPhaseDelay: 0,
              winnerUsesBestTime: false,
              subject_lines: [],
              preview_texts: [],
              creatives: []
            },
            ui: {
              color: "#4e98f7",
              config_size: "lg",
              icons: [
                default_icons.EmailSplitTest,
                default_icons.EmailSplitTestWhite
              ],
              label: "Email Split Test",
              transitionEvents: "Sent"
            }
          },
          template
        )
      },
      Condition: {
        CheckEmailStatus: Object.assign(
          {
            type: "Condition",
            node_type: "CheckEmailStatus",
            config: {
              label: "",
              creativeId: null,
              campaignSearchType: "current",
              campaignId: null
            },
            ui: {
              color: "#F2D25B",
              icons: [default_icons.CheckEmailStatus, default_icons.MailWhite],
              label: "Check Email Status",
              transitionEvents: {
                opened: "Only Opened",
                not_opened: "Not Opened",
                clicked: "Opened and Clicked",
                sent: "Sent",
                not_sent: "Was Not Sent"
              }
            }
          },
          template
        ),
        IsInSegment: Object.assign(
          {
            type: "Condition",
            node_type: "IsInSegment",
            config: {
              label: "",
              segmentId: 123
            },
            ui: {
              color: "#E66949",
              icons: [default_icons.IsInSegment, default_icons.SegmentWhite],
              label: "Is in Segment",
              transitionEvents: {
                yes: "Yes",
                no: "No"
              }
            }
          },
          template
        )
      }
    };
  },
  getNodeTemplateForType(type, nodeType) {
    const nodeTypes = this.getNodeTemplatesByType();
    return nodeTypes[type][nodeType] || null;
  },
  getAutomationCampaignTemplate() {
    return {
      isSimple: false,
      id: null,
      name: "",
      status: 1,
      startDate: null,
      timeZoneOffset: 0,
      type: 3
    };
  },
  getSimpleCampaignTemplate() {
    return {
      creative: null,
      fromLine: "",
      isSimple: true,
      id: null,
      name: "",
      previewText: "",
      segments: "",
      splitTest: {
        testSizePercent: 15,
        creatives: [],
        enabled: false,
        previewTexts: [],
        subjectLines: [],
        type: "subject",
        winnerDecisionDelay: 0,
        winnerMetric: "open",
        winnerSendDelay: 0
      },
      status: 1,
      startDate: null,
      subjectLine: "",
      timeZoneOffset: 0,
      type: 1
    };
  },
  getTransitionText(transition) {
    if (!transition.startNode || !transition.event) {
      return "";
    }

    const template = this.getNodeTemplateForType(
      transition.startNode.type,
      transition.startNode.node_type
    );

    if (!template.ui.transitionEvents) {
      return "";
    }

    if (typeof template.ui.transitionEvents !== "object") {
      return template.ui.transitionEvents;
    }
    return template.ui.transitionEvents[transition.event];
  },
  getCampaigns() {
    return axios.get("/campaigns");
  },
  searchCampaigns(searchCriteria) {
    return axios.get("/campaigns", { params: searchCriteria });
  },
  getCampaignNodes(campaignID) {
    if (campaignID) {
      return axios.get("/campaign_nodes?campaign.id=" + campaignID);
    }

    return new Promise(resolve => {
      resolve({
        data: [
          {
            configuration: {
              type: "Trigger",
              node_type: "Segment",
              position: {
                x: 200,
                y: 120
              },
              config: {
                label: "LD Internal",
                segmentId: 1,
                condition: "all"
              },
              transitions: {
                "f7e2ab11-724f-483e-8fd7-c3dfbeb060c2": {
                  next: "ff7394a1-56aa-cd6c-a0e2-1c7df8eea02a",
                  tuid: "f7e2ab11-724f-483e-8fd7-c3dfbeb060c2",
                  event: true
                },
                "f7e2ab11-724f-483e-8fd7-c3dfbeb060c3": {
                  next: "ff7394a1-56aa-cd6c-a0e2-1c7df8eea02b",
                  tuid: "f7e2ab11-724f-483e-8fd7-c3dfbeb060c3",
                  event: true
                },
                "f7e2ab11-724f-483e-8fd7-c3dfbeb060c5": {
                  next: "ff7394a1-56aa-cd6c-a0e2-1c7df8eea02d",
                  tuid: "f7e2ab11-724f-483e-8fd7-c3dfbeb060c5",
                  event: true
                },
                "f7e2ab11-724f-483e-8fd7-c3dfbeb060c6": {
                  next: "ff7394a1-56aa-cd6c-a0e2-1c7df8eea02e",
                  tuid: "f7e2ab11-724f-483e-8fd7-c3dfbeb060c6",
                  event: true
                }
              },
              nuid: "gffa2b78-c883-56c9-c98a-805b9db16728"
            }
          },
          {
            configuration: {
              type: "Action",
              node_type: "SendEmail",
              position: {
                x: 1000,
                y: 320
              },
              config: {
                label: "20 Off 150 June",
                dbNodeID: 54321,
                creativeId: null,
                fromLineId: null,
                subject: "",
                previewText: "",
                useBestDay: true
              },
              transitions: {
                "f7e2ab11-724f-483e-8fd7-c3dfbeb060c4": {
                  next: "ff7394a1-56aa-cd6c-a0e2-1c7df8eea02e",
                  tuid: "f7e2ab11-724f-483e-8fd7-c3dfbeb060c4",
                  event: true
                }
              },
              nuid: "ff7394a1-56aa-cd6c-a0e2-1c7df8eea02a"
            }
          },
          {
            configuration: {
              type: "Action",
              node_type: "SendSplitTestEmail",
              position: {
                x: 1000,
                y: 520
              },
              config: {
                label: "30 Off 150 June",
                fromLineId: null,
                testSizePercent: 15,
                testType: "subject",
                winnerMetric: "open",
                sendWinningEmail: 24,
                analysisPhaseDelay: 0,
                winnerUsesBestTime: false,
                subject_lines: [],
                preview_texts: [],
                creatives: []
              },
              transitions: {},
              nuid: "ff7394a1-56aa-cd6c-a0e2-1c7df8eea02b"
            }
          },
          {
            configuration: {
              type: "Action",
              node_type: "AutoResendEmail",
              isValid: false,
              isNew: false,
              position: {
                x: 200,
                y: 320
              },
              config: {
                label: "40 Off 150 June",
                previousEmailNodeUUID: null,
                newSubject: null,
                newPreviewText: null
              },
              transitions: {},
              nuid: "ff7394a1-56aa-cd6c-a0e2-1c7df8eea02c"
            }
          },
          {
            configuration: {
              type: "Action",
              node_type: "UpdateField", //TODO missing on the server side
              isValid: false,
              isNew: false,
              position: {
                x: 1000,
                y: 720
              },
              config: {
                label: "50 Off 150 June"
              },
              transitions: {},
              nuid: "ff7394a1-56aa-cd6c-a0e2-1c7df8eea02d"
            }
          },
          {
            configuration: {
              type: "Action",
              node_type: "AddDelay",
              isValid: false,
              isNew: false,
              position: {
                x: 1000,
                y: 120
              },
              config: {
                label: "60 Off 150 June",
                numberOfUnits: 1,
                unitOfTime: "day",
                type: "standard",
                specificDateTime: null,
                specificTimezone: null
              },
              transitions: {},
              nuid: "ff7394a1-56aa-cd6c-a0e2-1c7df8eea02e"
            }
          },
          {
            configuration: {
              type: "Action",
              node_type: "AddContactToSegment",
              isValid: false,
              isNew: false,
              position: {
                x: 1300,
                y: 120
              },
              config: {
                label: "60 Off 150 June",
                segmentId: 1
              },
              transitions: {},
              nuid: "ff7394a1-56aa-cd6c-a0e2-1c7df8eea02f"
            }
          },
          {
            configuration: {
              type: "Action",
              node_type: "RemoveContactFromSegment",
              isValid: false,
              isNew: false,
              position: {
                x: 1300,
                y: 320
              },
              config: {
                label: "60 Off 150 June",
                segmentId: 3
              },
              transitions: {},
              nuid: "ff7394a1-56aa-cd6c-a0e2-1c7df8eea02g"
            }
          },
          {
            configuration: {
              type: "Action",
              node_type: "Eject",
              isValid: false,
              isNew: false,
              position: {
                x: 1300,
                y: 520
              },
              config: {
                label: "Eject From Campaign"
              },
              transitions: {},
              nuid: "ff7394a1-56aa-cd6c-a0e2-1c7df8eea02h"
            }
          },
          {
            configuration: {
              type: "Condition",
              node_type: "CheckEmailStatus",
              isValid: false,
              isNew: false,
              position: {
                x: 1300,
                y: 720
              },
              config: {
                label: "Checking Newsletter",
                creativeId: null,
                campaignSearchType: "current",
                campaignId: null
              },
              transitions: {},
              nuid: "ff7394a1-56aa-cd6c-a0e2-1c7df8eea02i"
            }
          },
          {
            configuration: {
              type: "Condition",
              node_type: "IsInSegment",
              isValid: false,
              isNew: false,
              position: {
                x: 200,
                y: 720
              },
              config: {
                label: "60 Off 150 June"
              },
              transitions: {},
              nuid: "ff7394a1-56aa-cd6c-a0e2-1c7df8eea02j"
            }
          },
          {
            configuration: {
              type: "Condition",
              node_type: "CheckField", //TODO missing on the server side
              isValid: false,
              isNew: false,
              position: {
                x: 200,
                y: 520
              },
              config: {
                label: "60 Off 150 June"
              },
              transitions: {
                "f7e2ab11-724f-483e-8fd7-c3dfbeb060cz": {
                  //next: 'ff7394a1-56aa-cd6c-a0e2-1c7df8eea02c',
                  next: "ff7394a1-56aa-cd6c-a0e2-1c7df8eea02j",
                  tuid: "f7e2ab11-724f-483e-8fd7-c3dfbeb060cz",
                  event: "yes"
                },
                "f7e2ab11-724f-483e-8fd7-c3dfbeb060cy": {
                  next: "ff7394a1-56aa-cd6c-a0e2-1c7df8eea02j",
                  tuid: "f7e2ab11-724f-483e-8fd7-c3dfbeb060cy",
                  event: "no"
                }
              },
              nuid: "ff7394a1-56aa-cd6c-a0e2-1c7df8eea02k"
            }
          }
        ]
      });
    });
  },
  throwValidationError({ color, content }) {
    store.commit("snackbar/showMessage", {
      color,
      content
    });
    throw Error(content);
  },
  validateNode(configuration) {
    const errors = [];
    if (!configuration.config.name) {
      this.throwValidationError({
        color: "warning",
        content: "A Label is required."
      });
    }
    switch (configuration.node_type) {
      case "Segment":
        if (!configuration.config.segmentId) {
          this.throwValidationError({
            color: "warning",
            content: "The Segment field is required."
          });
        }
        if (!configuration.config.condition) {
          this.throwValidationError({
            color: "warning",
            content: "The Trigger Type field is required."
          });
        }
        break;
      case "SendEmail":
        if (!configuration.config.fromLineId) {
          this.throwValidationError({
            color: "warning",
            content: "The From field is required."
          });
        }
        if (
          !configuration.config.subject ||
          !configuration.config.subject.trim()
        ) {
          this.throwValidationError({
            color: "warning",
            content: "The Subject Line field is required."
          });
        }
        if (
            configuration.config.subject &&
            configuration.config.subject.length > 255
        ) {
          this.throwValidationError({
            color: "warning",
            content: "This Subject Line is too long."
          });
        }
        if (
            configuration.config.previewText &&
            configuration.config.previewText.length > 150
        ) {
          this.throwValidationError({
            color: "warning",
            content: "This Preview Text is too long."
          });
        }
        if (!configuration.config.creativeId) {
          this.throwValidationError({
            color: "warning",
            content: "The Content field is required."
          });
        }
        break;
      case "SendSplitTestEmail":
        if (!configuration.config.fromLineId) {
          this.throwValidationError({
            color: "warning",
            content: "The From field is required."
          });
        }

        if (!configuration.config.testType) {
          this.throwValidationError({
            color: "warning",
            content: "The A/B Test Type field is required."
          });
        } else if (configuration.config.testType === "subject") {
          for (let i = 0; i < configuration.config.type.length; i++) {
            const testValue = configuration.config.type[i];
            if (
                !configuration.config.tests[testValue].subject ||
                !configuration.config.tests[testValue].subject.trim()
            ) {
              errors.push(
                  "The Subject Line for Test " + "ABC"[i] + " is required."
              );
            }
            if (
                configuration.config.tests[testValue].subject &&
                configuration.config.tests[testValue].subject.length > 255
            ) {
              errors.push(
                  "The Subject Line for Test " + "ABC"[i] + " is too long."
              );
            }
            if (
                configuration.config.tests[testValue].previewText &&
                configuration.config.tests[testValue].previewText.length > 150
            ) {
              errors.push(
                  "This Preview Text for Test " + "ABC"[i] + " is too long."
              );
            }
          }
          for (let i = 0; i < (configuration.config.type.length || 2); i++) {
            if (
              configuration.config.subject_lines &&
              configuration.config.subject_lines.length
            ) {
              if (
                !configuration.config.subject_lines[i] ||
                !configuration.config.subject_lines[i].trim()
              ) {
                errors.push(
                  "The Subject Line field for Test " +
                    "ABC"[i] +
                    " is required."
                );
              }
            }
          }

          if (!configuration.config.creativeId) {
            this.throwValidationError({
              color: "warning",
              content: "The Content field is required."
            });
          }
        } else if (configuration.config.testType === "content") {
          if (
            !configuration.config.subject ||
            !configuration.config.subject.trim()
          ) {
            this.throwValidationError({
              color: "warning",
              content: "The Subject Line field is required."
            });
          }
          if (
              configuration.config.subject &&
              configuration.config.subject.length > 255
          ) {
            this.throwValidationError({
              color: "warning",
              content: "This Subject Line is too long."
            });
          }
          if (
              configuration.config.previewText &&
              configuration.config.previewText.length > 150
          ) {
            this.throwValidationError({
              color: "warning",
              content: "This Preview Text is too long."
            });
          }

          for (let i = 0; i < configuration.config.type.length; i++) {
            const testValue = configuration.config.type[i];
            if(!configuration.config.tests[testValue].creativeId)
              errors.push(
                "The Content field for Test " + "ABC"[i] + " is required."
              );
          }
        }

        if (
          isNaN(configuration.config.testSizePercent) ||
          configuration.config.testSizePercent < 5 ||
          configuration.config.testSizePercent > 100
        ) {
          this.throwValidationError({
            color: "warning",
            content: "The A/B Test Sample Size field is not valid."
          });
        }

        if (
          configuration.config.winnerMetric !== "open" &&
          configuration.config.winnerMetric !== "click"
        ) {
          this.throwValidationError({
            color: "warning",
            content: "The A/B Test Winner Selection field is not valid."
          });
        }

        if (isNaN(configuration.config.sendWinningEmail)) {
          this.throwValidationError({
            color: "warning",
            content: "The A/B Test Time Frame field is not valid."
          });
        }

        if (isNaN(configuration.config.analysisPhaseDelay)) {
          this.throwValidationError({
            color: "warning",
            content: "The A/B Test Send Winning Email field is not valid."
          });
        }
        break;
      case "AutoResendEmail":
        if (!configuration.config.main_node_type) {
          errors.push(
            "An email action must be connected to this action in to configure it."
          );
        } else if (configuration.config.main_node_type === "SendEmail") {
          if (
            !configuration.config.newSubject ||
            !configuration.config.newSubject.trim()
          ) {
            this.throwValidationError({
              color: "warning",
              content: "The Alternate Subject Line field is required."
            });
          }
          if (
              configuration.config.newSubject &&
              configuration.config.newSubject.length > 255
          ) {
            this.throwValidationError({
              color: "warning",
              content: "This Alternate Subject Line is too long."
            });
          }
          if (
              configuration.config.newPreviewText &&
              configuration.config.newPreviewText.length > 150
          ) {
            this.throwValidationError({
              color: "warning",
              content: "This Alternate Preview Text is too long."
            });
          }
        }
        break;
      case "UpdateField":
        break;
      case "AddDelay":
        if (configuration.config.type === 'specific') {
          if (!configuration.config.specificDateTime) {
            this.throwValidationError({
              color: "warning",
              content: "The Date/Time field is required."
            });
          }
          if (!configuration.config.specificTimezone) {
            this.throwValidationError({
              color: "warning",
              content: "The Time Zone field is required."
            });
          }
        }

        if (!configuration.config.unitOfTime) {
          this.throwValidationError({
            color: "warning",
            content: "The unit of time field is required."
          });
        }
        if (!configuration.config.numberOfUnits) {
          this.throwValidationError({
            color: "warning",
            content: "The number of units field is required."
          });
        } else if (configuration.config.numberOfUnits <= 0) {
          this.throwValidationError({
            color: "warning",
            content: "The number of units field field must be greater than 0."
          });
        }
        break;
      case "AddContactToSegment":
      case "RemoveContactFromSegment":
      case "IsInSegment":
        if (!configuration.config.segmentId) {
          this.throwValidationError({
            color: "warning",
            content: "The Segment field is required."
          });
        }
        break;
      case "CheckEmailStatus":
        if (!configuration.config.creativeId) {
          this.throwValidationError({
            color: "warning",
            content: "The Email Message field is required."
          });
        }
        if (
          configuration.config.campaignSearchType === "other" &&
          !configuration.config.campaignId
        ) {
          this.throwValidationError({
            color: "warning",
            content: "The Campaign field is required."
          });
        }
        break;
      case "CheckField":
        break;
    }

    if (errors.length > 0) {
      let content = errors[0];
      for(var j = 1; j < errors.length; j++) {
        content += (", " + errors[j])
      }
      store.commit("snackbar/showMessage", {
        color: "warning",
        content
      });
      throw new Error(JSON.stringify(errors));
    }
  },
  validateTransition(transition, transitions, nodes) {
    const errors = [];

    if (Object.keys(transition.targetNode.transitions).length > 0
      && transitionCreatesInfiniteLoop(transition, nodes)
    ) {
      store.commit("snackbar/showMessage", {
        color: "warning",
        content: "Infinite loops are not allowed"
      });
      return errors;
    }

    for (let tuid in transition.startNode.transitions) {
      const existingTransition = { ...transitions[tuid] };
      const transitionToCheck = { ...transition };
      if (
        existingTransition.targetNode.nuid ===
          transitionToCheck.targetNode.nuid &&
        // Both transitions have no event
        ((!transitionToCheck.event && !existingTransition.event) ||
          // Or the events on both transitions are the same
          transitionToCheck.event === existingTransition.event)
      ) {
        store.commit("snackbar/showMessage", {
          color: "warning",
          content: "This transition exists already"
        });
        return errors;
      }
    }

    return null;
  },
  saveCampaign(campaignData) {
    if (campaignData.isSimple) {
      campaignData = this.transformFromSimpleFormat(campaignData);
    }

    if (campaignData.id) {
      return axios.put("/campaigns/" + campaignData.id, campaignData);
    }

    return axios.post("/campaigns", campaignData);
  },
  getCampaign(campaignID) {
    return axios.get("/campaigns/" + campaignID).then(success => {
      /** @var {{data: {draftConfiguration: Object}}} success */
      if (success.data.status === 1) {
        success.data.campaignNodes = success.data.draftConfiguration;
      }
      return success.map((item, index) => ({ ...item, nuid: index }));
    });
  },
  getCampaignWithNodes(campaignID) {
    return axios
      .get("/campaigns/" + campaignID + "?groups[]=campaignNodes")
      .then(success => {
        /** @var {{data: {draftConfiguration: Object}}} success */
        if (success.data.status === 1) {
          success.data.campaignNodes = success.data.draftConfiguration;
        }

        return success;
      });
  },
  transformFromSimpleFormat(campaign) {
    const fullCampaign = {
      id: campaign.id || null,
      name: campaign.name || "",
      type: campaign.type || 1,
      startDate: campaign.startDate || null,
      timeZoneOffset: campaign.timeZoneOffset || 0,
      status: campaign.status || 1
    };

    const nodes = [];
    const movementX = 400;

    let totalX = 0;
    campaign.segments.forEach(
      (segment, index) => (totalX += 200 + index * movementX)
    );
    const emailNodeX =
      campaign.segments.length > 1
        ? Math.round(totalX / campaign.segments.length)
        : 200;

    let emailNode = {};
    if (campaign.splitTest.enabled) {
      emailNode = {
        type: "Action",
        node_type: "SendSplitTestEmail",
        config: {
          label: "Simple Email Split Test",
          winnerUsesBestTime: campaign.winnerUsesBestTime,
          fromLineId: campaign.fromLine,
          testType: campaign.splitTest.type,
          testSizePercent: campaign.splitTest.testSizePercent,
          winnerMetric: campaign.splitTest.winnerMetric,
          sendWinningEmail: campaign.splitTest.winnerDecisionDelay,
          analysisPhaseDelay: campaign.splitTest.winnerSendDelay,
          subject: campaign.subjectLine,
          previewText: campaign.previewText,
          creative: campaign.creative,
          subject_lines: campaign.splitTest.subjectLines,
          preview_texts: campaign.splitTest.previewTexts,
          creatives: campaign.splitTest.creatives
        },
        nuid: "split-test-email-node"
      };
    } else {
      emailNode = {
        type: "Action",
        node_type: "SendEmail",
        config: {
          label: "Simple Email",
          creativeId: campaign.creative,
          fromLineId: campaign.fromLine,
          subject: campaign.subjectLine,
          previewText: campaign.previewText,
          useBestDay: false
        },
        nuid: "send-email-node"
      };
    }
    emailNode.position = {
      x: emailNodeX,
      y: 600
    };
    emailNode.statistics = {
      entered: null,
      processing: null,
      completed: null,
      failed: null,
    };
    nodes.push({
      configuration: emailNode,
      isRecurringTrigger: false
    });

    for (let i in campaign.segments) {
      const transitions = {};
      const tuid = "simple-trigger-" + i + "to-email";
      transitions[tuid] = {
        tuid,
        next: emailNode.nuid,
        event: true
      };

      nodes.push({
        configuration: {
          type: "Trigger",
          node_type: "Segment",
          config: {
            label: campaign.segments[i].name,
            segmentId: campaign.segments[i].id,
            condition: "all"
          },
          transitions,
          position: {
            x: 200 + i * movementX,
            y: 200
          },
          statistics: {
            entered: null,
            processing: null,
            completed: null,
            failed: null,
          },
          nuid: "simple-trigger-" + i
        },
        isRecurringTrigger: false
      });
    }

    if (campaign.status > 1) {
      fullCampaign.draftConfiguration = [];
      fullCampaign.campaignNodes = nodes;
    } else {
      fullCampaign.draftConfiguration = nodes;
    }

    return fullCampaign;
  },
  validateSimpleEmailCampaign(campaignData) {
    return new Promise(resolve => {
      const errors = [];

      if (campaignData.segments.length === 0) {
        errors.push(
          "The To field is required. Please choose at least one segment to send to."
        );
      }

      const campaign = campaignData.campaign;

      if (!campaign.fromLine) {
        errors.push(
          "The From field is required. Please choose a from line to send from."
        );
      }

      if (!campaign.splitTest.enabled) {
        if (!campaign.subjectLine.trim()) {
          store.commit("snackbar/showMessage", {
            color: "warning",
            content: "The Subject Line field is required."
          });
        }

        if (!campaign.creative) {
          store.commit("snackbar/showMessage", {
            color: "warning",
            content: "The Content field is required."
          });
        }
      } else {
        if (!campaign.splitTest.type) {
          store.commit("snackbar/showMessage", {
            color: "warning",
            content: "The A/B Test Type field is required."
          });
        } else if (campaign.splitTest.type === "subject") {
          if (campaign.splitTest.subjectLines.length === 0) {
            for (let i = 0; i < (campaign.splitTest.type.length || 2); i++) {
              errors.push(
                "The Subject Line field for Test " + "ABC"[i] + " is required."
              );
            }
          } else {
            for (let i in campaign.splitTest.subjectLines) {
              if (!campaign.splitTest.subjectLines[i].trim()) {
                errors.push(
                  "The Subject Line field for Test " +
                    "ABC"[i] +
                    " is required."
                );
              }
            }
          }

          if (!campaign.creative) {
            store.commit("snackbar/showMessage", {
              color: "warning",
              content: "The Content field is required."
            });
          }
        } else if (campaign.splitTest.type === "content") {
          if (!campaign.subjectLine) {
            store.commit("snackbar/showMessage", {
              color: "warning",
              content: "The Subject Line field is required."
            });
          }

          if (campaign.splitTest.creatives.length === 0) {
            for (let i = 0; i < (campaign.splitTest.type.length || 2); i++) {
              errors.push(
                "The Content field for Test " + "ABC"[i] + " is required."
              );
            }
          } else {
            for (let i in campaign.splitTest.creatives) {
              if (!campaign.splitTest.creatives[i]) {
                errors.push(
                  "The Content field for Test " + "ABC"[i] + " is required."
                );
              }
            }
          }
        }

        if (
          isNaN(campaign.splitTest.testSizePercent) ||
          campaign.splitTest.testSizePercent < 5 ||
          campaign.splitTest.testSizePercent > 100
        ) {
          store.commit("snackbar/showMessage", {
            color: "warning",
            content: "The A/B Test Sample Size field is not valid."
          });
        }

        if (
          campaign.splitTest.winnerMetric !== "open" &&
          campaign.splitTest.winnerMetric !== "click"
        ) {
          store.commit("snackbar/showMessage", {
            color: "warning",
            content: "The A/B Test Winner Selection field is not valid."
          });
        }

        if (isNaN(campaign.splitTest.winnerDecisionDelay)) {
          store.commit("snackbar/showMessage", {
            color: "warning",
            content: "The A/B Test Time Frame field is not valid."
          });
        }

        if (isNaN(campaign.splitTest.winnerSendDelay)) {
          store.commit("snackbar/showMessage", {
            color: "warning",
            content: "The A/B Test Send Winning Email field is not valid."
          });
        }
      }

      if (errors.length > 0) {
        return;
      }

      resolve();
    });
  }
};

export { checkIfValid, getPreviousEmailNodes }
